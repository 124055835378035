<template>
  <div id="app">
    <SearchPanelOverlay />
    <header id="header" role="banner" class="container is-fluid is-flex-grow-0 is-translucent">
      <a href="#main" id="header-link-skip" class="skip-to-main">Skip to main content</a>
      <MaintenanceBanner />
      <AuthError />
      <AuthHeader />
      <Navigation />
      <Toolbar v-show="!this.$route.meta.hideToolbar" />
    </header>
    <main id="main" role="main" class="container is-fluid">
      <router-view :id="this.$route.meta.elementId" style="height: 100%;" :class="{ 'has-background-white-ter': hasGreyBackground }" />
    </main>
    <Footer role="contentinfo" class="is-flex-grow-0" v-once />
  </div>
</template>

<script>
import MaintenanceBanner from '@/components/maintenance-banner';
import AuthError from '@/components/auth-error';
import AuthHeader from '@/components/auth-header';
import Navigation from '@/components/navigation';
import Toolbar from '@/components/toolbar';
import Footer from '@/components/footer';
import SearchPanelOverlay from "@/components/shared/search-overlay";
import { urlDecoder } from '@/api';
import { mapActions } from "vuex";

export default {
  name: 'App',
  metaInfo: {
    // Fallback <title> if view component doesn't specify a metaInfo.title
    title: 'MarinLit - A database of the marine natural products literature',
    titleTemplate: '%s | MarinLit',
  },
  computed: {
    hasGreyBackground() {
      return ['Login', 'Compounds', 'Articles'].includes(this.$route.name);
    },
  },
  components: {
    MaintenanceBanner,
    AuthError,
    AuthHeader,
    Navigation,
    Toolbar,
    Footer,
    SearchPanelOverlay,
  },
  methods: {
    ...mapActions('search', [
      'updateAllSearchResultsAction',
    ]),
  },
  async mounted() {
    // Check if a search query string exists on startup. If so, pass it to the
    // URL decoder - which pushes valid search parameters into state, populating
    // the form fields and search pills - and finally run a search.
    if (Object.keys(this.$route.query).filter(k => k !== 'redirect').length) {
      urlDecoder.importSearchFromQueryString(this.$route.query);
      await this.updateAllSearchResultsAction();
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
