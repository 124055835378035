<template>
  <div>
    <ServiceErrorMessage v-if="ServiceStatus.HasError" :error="ServiceStatus.Error" @retry="retry" />
    <div v-else-if="SearchResultsTotalCount > 0" class="px-5 py-5">
      <h1 class="is-sr-only" id="heading-compound-search-results">
        {{ SearchResultsTotalCount }} compound search results
      </h1>
      <CompoundList v-if="ViewMode === 'list'" :compounds="SearchResults" />
      <CompoundGrid v-else-if="ViewMode === 'grid'" :compounds="SearchResults" />
      <CompoundMap v-else-if="ViewMode === 'map'" :compoundLocations="CompoundLocations" />
      <LoadMoreButton v-show="showLoadMoreButton" :isLoading="ServiceStatus.AwaitingResponse" @click="loadMoreResults" />
    </div>
    <div v-else>
      <NoResultsMessage v-if="!ServiceStatus.AwaitingResponse" searchType="compounds" />
    </div>
  </div>
</template>

<script>
import CompoundList from "@/components/compound-results/compound-list";
import CompoundGrid from "@/components/compound-results/compound-grid";
import CompoundMap from "@/components/compound-results/compound-map";
import LoadMoreButton from "@/components/shared/load-more-button";
import NoResultsMessage from "@/components/shared/no-results";
import ServiceErrorMessage from "@/components/shared/service-error-message";
import { mapState, mapActions } from "vuex";
export default {
  name: 'Compounds',
  metaInfo: {
    title: 'Compound search results'
  },
  async created() {
    // Prevent duplicates from being added to search results
    if (!this.SearchResults.length) {
      await this.updateCompoundSearchResultsAction(true);
    }
  },
  methods: {
    ...mapActions('compounds', [
      'updateCompoundSearchResultsAction'
    ]),
    async loadMoreResults() {
      await this.updateCompoundSearchResultsAction(false);
    },
    async retry() {
      await this.updateCompoundSearchResultsAction(false);
    },
  },
  computed: {
    ...mapState('compounds', {
      SearchResults: state => state.SearchResults,
      ViewMode: state => state.ViewMode,
      CompoundLocations: state => state.CompoundLocations,
      ServiceStatus: state => state.ServiceStatus
    }),
    ...mapState('search', {
      SearchResultsTotalCount: state => state.SearchResultCounts.compounds,
    }),
    showLoadMoreButton() {
      return (
        this.SearchResults.length < this.SearchResultsTotalCount &&
        this.ViewMode !== 'map'
      );
    }
  },
  components: {
    CompoundList,
    CompoundGrid,
    CompoundMap,
    LoadMoreButton,
    NoResultsMessage,
    ServiceErrorMessage,
  },
};
</script>
