<template>
  <div>
    <div class="columns is-marginless is-translucent is-centered-touch">
      <div class="column is-one-third is-hidden-touch">
        <div class="level is-marginless">
          <div class="level-left">
            <router-link class="site-title h--medium is-size-1 level-item" id="header-link-ml"
              aria-label="MarinLit homepage" to="/">MarinLit</router-link>
          </div>
        </div>
        <span class="is-block is-size-7 has-text-white" style="margin-left: 3px;">Dedicated to marine natural products
          research</span>
      </div>
      <div class="column is-8-tablet is-offset-2-tablet is-one-third-desktop is-offset-0-desktop">
        <nav class="nav-tabs" role="navigation" aria-label="Main">
          <ul id="header-nav" class="columns is-mobile is-centered">
            <li v-for="(tab, index) in tabs" :key="index" class="column is-5 is-4-fullhd">
              <router-link class="has-text-weight-bold" :to="tab.path" :id="`header-nav-${tab.title.toLowerCase()}`"
                :event="tab.path === $route.path ? '' : 'click'">
                {{ tab.title }}
                <animated-integer v-if="tab.counter" class="is-block has-text-weight-normal"
                  :value="getResultsCount(tab.counter)"></animated-integer>
                <span v-if="tab.counter" class="is-sr-only">search results</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-if="banner.show">
      <div class="banner">
        <span class="banner-title" v-if="banner.header != ''">
          {{ banner.header }}
        </span>
        <p>
          {{ banner.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedInteger from '@/components/utils/animated-integer';
import { mapState } from 'vuex';
export default {
  name: 'Header',
  data() {
    return {
      tabs: [
        {
          title: 'Compounds',
          path: '/compounds',
          counter: 'compoundSearchResultsCount',
        },
        {
          title: 'Articles',
          path: '/articles',
          counter: 'articleSearchResultsCount',
        }
      ],
      banner: {
        header: "",
        message: "",
        show: false
      }
    };
  },
  async mounted() {
    try {
      const response = await fetch(`/config.json`);
      const res = await response.json();
      const notification = res.notification;
      if (notification.show) {
        let todayDate = new Date();
        let startDate = new Date(notification.startDate);
        let endDate = new Date(notification.endDate);

        const londonTimeZone = 'Europe/London';
        const today = new Date(todayDate.toLocaleString('en-US', { timeZone: londonTimeZone }));
        const start = new Date(startDate.toLocaleString('en-US', { timeZone: londonTimeZone }));
        const end = new Date(endDate.toLocaleString('en-US', { timeZone: londonTimeZone }));

        let validDate = notification.show && today >= start && today < end;

        this.banner.header = notification.header;
        this.banner.message = notification.message;
        this.banner.show = notification.show && validDate;
      }
    } catch (error) {
      console.error('Error fetching JSON content:', error);
    }
  },
  methods: {
    getResultsCount(counter) {
      return this[counter];
    },
  },
  computed: {
    ...mapState('search', {
      compoundSearchResultsCount: state => state.SearchResultCounts.compounds,
      articleSearchResultsCount: state => state.SearchResultCounts.articles,
    }),
  },
  components: {
    AnimatedInteger,
  },
};
</script>

<style>
.banner {
  margin-top: 5px;
  width: 99%;
  border-radius: 5px;
  background-color: #FEE;
  border: 1px solid #EDD;
  color: #A66;
  padding: 25px;
  margin: auto;
  font-size: 20px;
}

.banner-title {
  font-weight: 700;
}

@media (max-width: 540px) {
  .banner {
    font-size: 14px;
  }
}
</style>
