<template>
  <div class="px-4 py-5">
    <div class="columns is-gapless content mb-0">
      <div class="column is-hidden-touch">
        <StartSearchingNow v-if="isAuthenticated" class="start-searching-now" fill="white" />
      </div>
      <div class="column has-text-right">
        <div class="pr-4">
          <p v-if="lastUpdatedDate" class="is-size-3-desktop is-size-4-tablet is-size-5-mobile">
            Last updated <b class="ml-4 is-block-touch is-inline-desktop">{{ lastUpdatedDate }}</b>
          </p>
          <p class="is-size-4 is-hidden-touch">
            Questions or comments?<br>
            <a class="hover-underline" href="mailto:marinlit@rsc.org">marinlit@rsc.org</a>
          </p>
        </div>
      </div>
    </div>

    <div class="columns is-centered content mt-5" id="home-page-body">
      <div class="column is-10-tablet is-8-desktop is-6-fullhd mb-6">
        
        <h1 class="marinlit title">
          MarinLit
        </h1>
        <p class="marinlit subtitle">
          Dedicated to marine natural products research
        </p>

        <div class="columns is-mobile total-count">
          <div class="column is-narrow" id="text-home-compounds-total">
            {{ allCompoundsCount }}
          </div>
          <div class="column">
            Compounds
          </div>
        </div>
        <div class="columns is-mobile total-count">
          <div class="column is-narrow" id="text-home-articles-total">
            {{ allArticlesCount }}
          </div>
          <div class="column">
            Articles
          </div>
        </div>

        <div v-if="!isAuthenticated" class="mb-6">
          <h2>Ready to explore MarinLit?</h2>
          <p>
            <router-link to="/login" id="btn-home-log-in" class="button is-large is-link is-outlined">
              Sign in
            </router-link>
          </p>
        </div>
        
        <p>
          MarinLit is a database dedicated to marine natural products research. It 
          contains a comprehensive range of data, along with powerful dereplication 
          features. 
        </p>
        
        <p>
          MarinLit is continuously updated with the latest articles and compounds 
          published in the literature. 
        </p>

        <h2>Coverage</h2>
        <p>
          Comprehensive coverage of marine natural products in journal articles,
          including new and revised compounds, synthesis, ecology and biological
          activities.
        </p>

        <h2>Dereplicate using:</h2>
        <ul>
          <li>Substructure</li>
          <li>Exact mass</li>
          <li>Molecular formula</li>
          <li>NMR structural features</li>
          <li>Calculated <sup>13</sup>C and <sup>1</sup>H NMR shift data</li>
          <li>UV max</li>
          <li>Taxonomy</li>
        </ul>

        <h2>Database history</h2>
        <p>
          MarinLit was established in the 1970s by Professors John Blunt and Murray 
          Munro at the University of Canterbury, New Zealand. Over the years, it has 
          evolved to contain unique searchable features and powerful dereplication 
          tools. MarinLit has been published by the Royal Society of Chemistry since 
          2014.
        </p>
        <div class="home-videos">
          <iframe
            src="https://www.youtube-nocookie.com/embed/Qg81-hUfUvQ">
          </iframe>
          <br/>
          <iframe
            src="https://www.youtube-nocookie.com/embed/vda32fNhrwo">
          </iframe>
        </div>

        <h2>Questions, comments or need to arrange access?</h2>
        <p class="mb-6">
          <a class="hover-underline" href="mailto:marinlit@rsc.org">marinlit@rsc.org</a><br>
          <a class="hover-underline" href="mailto:sales@rsc.org">sales@rsc.org</a>
        </p>
        <h2>MarinLit team</h2>
        <p><b>Tamara Hughes</b><br>Executive Editor, Chemistry data and databases</p>
        <p><b>Helen Potter</b><br>Content Editor, MarinLit</p>
        <p><b>Adrian Robinson</b><br>Content Editor, MarinLit</p>
        <p><b>Sarah Rogers</b><br>Content Editor, MarinLit</p>
        <p><b>Vicki Tillett</b><br>Content Editor, MarinLit</p>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StartSearchingNow from "@/components/shared/start-searching-now";
export default {
  name: 'Home',
  metaInfo: {
    // Don't append default ' | MarinLit' to home page <title> element
    titleTemplate: null,
  },
  computed: {
    ...mapState('compounds', {
      lastUpdatedDate: state => state.LastUpdatedDate,
      allCompoundsCount: state => state.AllCompoundsCount.toLocaleString(),
    }),
    ...mapState('articles', {
      allArticlesCount: state => state.AllArticlesCount.toLocaleString(),
    }),
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  components: {
    StartSearchingNow,
  },
};
</script>

<style lang="scss">
#page-home {
  

  .start-searching-now {
    width: 270px;
    height: 180px;
    margin-top: -1.5rem;
    margin-left: 2rem;
    @media only screen and (max-width: 1408px) {
        width: 231px;
        height: 154px;
    }
  }
  
  .content {
    color: white;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
    .title {
      &.marinlit {
        color: white;
        font-size: 5rem;
        letter-spacing: -0.05em;
      }
    }
    .subtitle {
      &.marinlit {
        color: white;
        font-size: 2rem;
        margin-bottom: 3rem;
        letter-spacing: 0.01em;
      }
    }
    .total-count {
      margin-bottom: 0;
      align-items: baseline;
      .column {
        padding-bottom: 0;
        &:nth-child(1) {
          font-size: 2.5rem;
          font-weight: bold;
        }
        &:nth-child(2) {
          font-size: 2rem;
        }
      }
      + .total-count {
        margin-bottom: 4rem;
        .column {
          padding-top: 0.75rem;
        }
      }
    }
    .button {
      border-color: white;
    }
    h2 {
      color: white;
      font-size: 1.5rem;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 1.25rem;
    }
    a {
      color: white;
      font-weight: 700;
    }
    ul {
      margin-left: 1em;
      font-size: 1.25rem;
    }
    li + li {
      margin-top: 0.5em;
    }
    .home-videos {
      display: flex;   
      gap: 4.5%;
      margin: 40px 0;
      @media only screen and (max-width: 840px) {
          flex-direction: column;
      }
      iframe {
        width: 45.44%;
        height: 315px;
        @media only screen and (max-width: 840px) {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
</style>
