import { Loader } from '@googlemaps/js-api-loader';

export default {
  data() {
    return {
      google: null,
      map: null,
      loader: new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      }),
    };
  },
  async mounted() {
    await this.initializeMap();
    this.updateMap();
  },
  methods: {
    async initializeMap() {
      const mapContainer = this.$refs.googleMap;
      await this.loader.load().then(() => {
        this.google = window.google;
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      })
      .catch(e => {
        console.error(e);
      });
    },
  },
};
