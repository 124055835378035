<template>
  <div class="has-background-white px-4 py-6 content" id="record-not-found-body">
    <h1 class="title">{{ error }}</h1>
    <h2 class="title is-4">Sorry, we couldn’t find the {{ type }} you were looking for</h2>
    <p class="is-size-5">This could be because:</p>
    <ul>
        <li>The page has been moved, updated or deleted</li>
        <li>You may have followed a broken link or typed the address incorrectly</li>
    </ul>
    <p class="is-size-5">Try a different search, or one of these links:</p>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/compounds">Compounds</router-link></li>
      <li><router-link to="/articles">Articles</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RecordError',
  props: {
    error: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ['compound', 'article'].indexOf(value) !== -1;
      }
    }
  }, 
}
</script>
