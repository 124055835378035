<template>
  <li class="pill" v-if="showPill">
    <div class="pill-content" :title="`${label}: ${formattedValue}`">
      <span class="pill-label">{{ label }}</span>
      <span class="pill-value">{{ formattedValue }}</span>
    </div>
    <a href @click.prevent="remove" class="pill-remove" role="button" :aria-label="`Remove ${label}: ${formattedValue} from search`">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
      </svg>
    </a>
  </li>
</template>

<script>
export default {
  name: 'SearchPill',
  props: {
    showPill: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Array],
    },
    variance: {
      type: String,
      default: '',
    },
    protons: {
      type: String,
      default: '',
    },
    formatterKey: {
      type: String,
      default: '',
    }
  },
  computed: {
    formattedValue() {
      let labelBuilder = [];
      switch (this.formatterKey) {
        case 'exactMass':
          return `${this.value} Da${this.variance ? ' ± ' + this.variance : ''}`;
        case 'uvMax':
          return `${this.value} nm${this.variance ? ' ± ' + this.variance : ''}`;
        case 'nmr':
          return `${this.value} ppm${this.variance ? ' ± ' + this.variance : ''}${this.protons ? ', Protons-' + this.protons : ''}`;
        case 'taxonomy':
          if (this.value[0]) labelBuilder.push(`Phylum-${this.value[0]}`);
          if (this.value[1]) labelBuilder.push(`Class-${this.value[1]}`);
          if (this.value[2]) labelBuilder.push(`Order-${this.value[2]}`);
          if (this.value[3]) labelBuilder.push(`Family-${this.value[3]}`);
          if (this.value[4]) labelBuilder.push(`Genus-${this.value[4]}`);
          if (this.value[5]) labelBuilder.push(`Species-${this.value[5]}`);
          if (this.value[6]) labelBuilder.push('Source of compound-yes');
          return labelBuilder.join(', ');
        case 'articleId':
          return `A${this.value}`;
        default:
          return this.value;
      }
    },
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
  },
}
</script>
