<template>
    <div class="rsc-ui">
        <!-- start oxygen footer -->
        <footer id="footer" class="rsc-footer">
            <div class="breadcrumbs">
                <div class="container">
                    <ul>
                        <li class="extra">
                            <a href="https://www.rsc.org/">rsc.org</a>
                        </li>
                        <li class="extra">
                            <img src="@/assets/footer/chevron-right-light.png" class="mx-1" width="8" height="8"
                                alt="" />
                            <a href="https://www.rsc.org/journals-books-databases/">Journals, books &amp; databases</a>
                        </li>
                        <li class="extra">
                            <img src="@/assets/footer/chevron-right-light.png" class="mx-1" width="8" height="8"
                                alt="" />
                            <a href="https://www.rsc.org/journals-books-databases/databases-literature-updates/">Databases
                                &amp; literature updates</a>
                        </li>
                        <li>
                            <img src="@/assets/footer/chevron-right-light.png" class="mx-1" width="8" height="8"
                                alt="" />
                            <router-link to="/">MarinLit</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="nav" id="pnlSiteMap">
                <div class="container">
                    <div class="rsc-logo">
                        <img src="@/assets/footer/rsc-logo-rev.svg" alt="Royal Society of Chemistry homepage"
                            width="340" height="85" />
                    </div>
                    <div class="links">
                        <ul>
                            <li><a href="https://www.rsc.org/">Home</a></li>
                            <li><a href="https://www.rsc.org/about-us/">About us</a></li>
                            <li><a href="https://www.rsc.org/membership-and-community/">Membership &#38; professional
                                    community</a></li>
                            <li><a href="https://www.rsc.org/campaigning-outreach/">Campaigning &#38; outreach</a></li>
                            <li><a href="https://www.rsc.org/journals-books-databases/">Journals, books &#38;
                                    databases</a></li>
                            <li><a href="https://www.rsc.org/teaching-and-learning/">Teaching &#38; learning</a></li>
                            <li><a href="https://www.rsc.org/news-events/">News &#38; events</a></li>
                            <li><a href="https://www.rsc.org/locations-contacts/">Locations &#38; contacts</a></li>
                            <li><a href="https://www.rsc.org/careers/">Careers</a></li>
                            <li><a href="https://www.rsc.org/awards-funding/">Awards &#38; funding</a></li>
                            <li><a href="https://www.rsc.org/advertise/">Advertise</a></li>
                            <li><a href="https://www.rsc.org/help-legal/">Help &#38; legal</a></li>
                            <li><a href="https://www.rsc.org/help-legal/legal/privacy/">Privacy policy</a></li>
                            <li><a href="https://www.rsc.org/help-legal/legal/terms-conditions/">Terms &#38;
                                    conditions</a></li>
                        </ul>
                    </div>
                    <hr class="clear" />
                </div>
            </div>
            <div class="baseline">
                <div class="container">
                    <div class="social-icons">
                        <a href="https://www.facebook.com/RoyalSocietyofChemistry" class="facebook"><img
                                src="@/assets/footer/facebook-128.png" height="32" width="32" alt="Facebook" /></a>
                        <a href="https://twitter.com/RoySocChem" class="twitter"><img
                                src="@/assets/footer/twitter-128.png" height="32" width="32" alt="Twitter" /></a>
                        <a href="https://www.linkedin.com/company/23105" class="linkedin"><img
                                src="@/assets/footer/linkedin-128.png" height="32" width="32" alt="LinkedIn" /></a>
                        <a href="https://www.youtube.com/user/wwwRSCorg" class="youtube"><img
                                src="@/assets/footer/youtube-128.png" height="32" width="32" alt="YouTube" /></a>
                    </div>
                    <div class="copyright">
                        &#169; Royal Society of Chemistry {{ currentYear }}
                        <br />Registered charity number: 207890
                    </div>
                </div>
            </div>
            <hr class="clear" />
            <div class="rsc-onetrust-cookie-footer">
                <div id="rsc-onetrust-cookie-footer-non-br">This website collects cookies to deliver a better user
                    experience.
                    <span id="rsc-onetrust-cookie-footer-global">
                        See how this site uses <a href="/cookies">Cookies</a>.
                    </span>
                    <span id="rsc-onetrust-cookie-footer-ca" style="display: none;">
                        <a href="/cookies">Do not sell my personal data</a>.
                    </span>
                </div>
                <div id="rsc-onetrust-cookie-footer-br" style="display: none;">
                    Este site coleta cookies para oferecer uma melhor experiência ao usuário.
                    <span>
                        Veja como este site usa <a href="/cookies">Cookies</a>.
                    </span>
                </div>
            </div>
        </footer>
        <!-- end oxygen footer -->
    </div>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
}
</script>

<style>
/* CSS Document */

/**** OVERRIDES AND REQUIRED STYLES FROM OLD OXYGEN ****/

@media only screen and (max-width: 1023px) {
    .rsc-ui .container {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.rsc-ui hr {
    clear: both;
}

.rsc-ui hr.clear {
    box-shadow: none;
    border-color: transparent;
    -webkit-margin-before: 0px;
    height: 0px;
    margin-bottom: -1px;
}

/*END OVERRIDES AND REQUIRED STYLES FROM OLD OXYGEN ****/

.rsc-ui footer.rsc-footer {
    background-color: #252729;
    clear: both;
    width: 100%;
    margin-top: 0px;
    font-family: "Gotham Medium", museo_sans_500regular, "museo-sans", Arial, sans-serif;
    font-weight: 500;
    position: relative;
}

.rsc-ui footer.rsc-footer .breadcrumbs {
    background-color: #383b3d;
    border-bottom: 1px solid #54585a;
    border-top: 1px solid #54585a;
    height: 40px;
}

.rsc-ui footer.rsc-footer .breadcrumbs ul {
    margin-left: 0px;
    margin-top: 8px;
    -webkit-margin-before: 8px;
    -webkit-margin-after: 8px;
    -webkit-padding-start: 8px;
}

.rsc-ui footer.rsc-footer .breadcrumbs li {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.rsc-ui footer.rsc-footer .breadcrumbs li a {
    color: #adadad;
    font-size: 13px;
    text-decoration: none;
}

.rsc-ui footer.rsc-footer .breadcrumbs li a:hover,
.rsc-ui footer.rsc-footer .breadcrumbs li a:focus .rsc-ui footer.rsc-footer .breadcrumbs li a.selected {
    color: #ffffff;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.rsc-ui footer.rsc-footer .breadcrumbs li.extra {
    display: none;
}

.rsc-ui footer.rsc-footer .rsc-logo {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 50%;
    float: left;
    text-align: center;
    padding: 32px 0 0 16px;
}

.rsc-ui footer.rsc-footer .rsc-logo img {
    width: 100%;
    height: auto;
}

.rsc-ui footer.rsc-footer .links {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 32px 0;
    width: 100%;
    float: right;
}

.rsc-ui footer.rsc-footer .links ul {
    margin: 0 8px;
    padding: 0;
}

.rsc-ui footer.rsc-footer .links ul li {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    float: left;
    list-style-type: none;
    line-height: 24px;
    padding: 0 0 0 16px;
    margin: 0;
    border-left: 1px solid #383b3d;
}

.rsc-ui footer.rsc-footer .links ul li a {
    color: #adadad;
    display: block;
    font-family: "Source Sans Pro", "source-sans-pro", museo_sans300, "museo-sans", Arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.rsc-ui footer.rsc-footer .links ul li a:hover,
.rsc-ui footer.rsc-footer .links ul li a:focus {
    color: #ffffff;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.rsc-ui footer.rsc-footer .baseline {
    border-top: 1px solid #383b3d;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: auto;
    min-height: 96px;
    height: 100%;
}

.rsc-ui footer.rsc-footer .baseline .copyright {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #adadad;
    font-size: 13px;
    padding: 8px;
    width: 100%;
    float: left;
    text-align: center;
    font-family: "Source Sans Pro", "source-sans-pro", museo_sans300, "museo-sans", Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
}

.rsc-ui footer.rsc-footer .social-icons {
    display: block;
    height: 64px;
    margin-right: 0px;
    margin-top: 4px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.rsc-ui footer.rsc-footer .social-icons a {
    display: inline-block;
    height: 48px;
    width: 48px;
    margin-right: 4px;
}

.rsc-ui footer.rsc-footer .social-icons a img {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    padding: 8px;
}

.rsc-ui footer.rsc-footer .facebook {
    background-color: #346da6;
}

.rsc-ui footer.rsc-footer .facebook:hover {
    background-color: transparent;
}

.rsc-ui footer.rsc-footer .twitter {
    background-color: #429ecc;
}

.rsc-ui footer.rsc-footer .twitter:hover {
    background-color: transparent;
}

.rsc-ui footer.rsc-footer .linkedin {
    background-color: #346da6;
}

.rsc-ui footer.rsc-footer .linkedin:hover {
    background-color: transparent;
}

.rsc-ui footer.rsc-footer .youtube {
    background-color: #ca4638;
}

.rsc-ui footer.rsc-footer .youtube:hover {
    background-color: transparent;
}

.rsc-ui footer.rsc-footer .social-icons span {
    display: none;
}

/* responsive classes */

/* small viewports */
@media only screen and (min-width: 480px) {
    .rsc-ui footer.rsc-footer .links ul li {
        width: 50%;
    }
}

/* mid viewports */
@media only screen and (min-width: 640px) {
    .rsc-ui footer.rsc-footer .social-icons {
        float: right;
    }

    .rsc-ui footer.rsc-footer .baseline .copyright {
        width: auto;
        text-align: left;
        padding: 8px;
    }
}

/* ipad specific viewports */
@media only screen and (min-width: 768px) {
    .rsc-ui footer.rsc-footer .links ul li {
        width: 33.33%;
    }

    .rsc-ui footer.rsc-footer .rsc-logo {
        padding: 88px 0 72px 16px;
        width: 360px;
    }

    .rsc-ui footer.rsc-footer .breadcrumbs li.extra {
        display: inline-block;
    }

    .rsc-ui footer.rsc-footer .social-icons {
        margin-right: 16px;
    }
}

/* full viewports */
@media only screen and (min-width: 960px) {}

/* wide viewports */
@media only screen and (min-width: 1168px) {
    .rsc-ui footer.rsc-footer .links {
        width: 55%;
        margin-top: 20px;
    }

    .rsc-ui footer.rsc-footer .links ul li {
        width: 50%;
    }
}

/* super wide viewports */
@media only screen and (min-width: 1460px) {
    .rsc-ui footer.rsc-footer .links {
        width: 60%;
        margin-top: 40px;
    }

    .rsc-ui footer.rsc-footer .links ul li {
        width: 33.33%;
    }
}
</style>
