<template>
  <div>

    <div class="is-sr-only">
      The drawing editor is not accessible at this time.
      Press Shift-Tab to select another search type.
    </div>
    
    <!------------------------------>
    <!--      DRAWING EDITOR      -->
    <!------------------------------>
    <div id="structure-panel-canvas">
      
      <div id="structure-panel-canvas-status" class="columns is-mobile is-centered is-vcentered is-marginless is-gapless">
        
        <!-- Progress bar -->
        <div id="structure-panel-canvas-loading" v-if="!ketcherLoaded && !ketcherError" class="column is-half">
          <progress class="progress is-primary" max="100"></progress>
        </div>
        
        <!-- Error message -->
        <div id="structure-panel-canvas-error" v-if="ketcherError" class="column is-half">
          <div class="message is-danger">
            <div class="message-body">
              <p class="is-size-4 mb-3"><b>Unable to load</b></p>
              <p>Sorry, there was a problem loading the drawing editor. Please refresh the page and try again.</p>
            </div>
          </div>
        </div>
        
        <!-- Viewport warning -->
        <div id="structure-panel-canvas-warning" v-if="!ketcherError" class="column is-half">
          <div class="message is-warning">
            <div class="message-body">
              <p class="is-size-4 mb-3"><b>Not enough room</b></p>
              <p>The drawing editor needs more horizontal space. Please widen your browser window or try a device with a larger screen.</p>
            </div>
          </div>
        </div>

      </div>
      
      <!-- Ketcher is inserted here -->
      <div id="structure-panel-canvas-container" role="application">
        <iframe id="ifKetcher" ref="ifKetcher" :src="ketcherFramePath" />
      </div>

    </div>

    <div class="has-text-centered mt-3">
      <button
        type="button"
        class="button is-primary is-rounded px-6"
        :class="{ 'is-loading': isLoading }"
        id="btn-update-structure-search"
        @click="updateSmiles">
        Apply current drawing to search
      </button>
    </div>

    <div class="px-5" id="structure-panel-options">

      <h3 class="has-text-grey mb-4 is-size-5">Match</h3>
      
      <!-- Substructure search -->
      <div class="columns is-mobile is-gapless mb-3" id="structure-panel-options-substructure">
        <div class="column is-narrow">
          <input
            type="radio"
            id="substructure"
            value="substructure"
            name="searchType"
            v-model="searchType"
            v-on="smiles.length ? { change: updateSearch } : {}" />
        </div>
        <div class="column">
          <label for="substructure"><b>Substructure search</b></label>
        </div>
      </div>

      <!-- Exact search -->
      <div class="columns is-mobile is-gapless mb-3" id="structure-panel-options-exact">
        <div class="column is-narrow">
          <input
            type="radio"
            id="exact"
            value="exact"
            name="searchType"
            v-model="searchType"
            v-on="smiles.length ? { change: updateSearch } : {}" />
        </div>
        <div class="column">
          <label for="exact"><b>Exact search</b></label>
        </div>
      </div>

      <!-- Similarity search -->
      <div class="columns is-multiline is-gapless mb-3" id="structure-panel-options-similarity">
        <div class="column is-one-third">
          <div class="columns is-gapless is-mobile">
            <div class="column is-narrow">
              <input
                type="radio"
                id="similarity"
                value="similarity"
                name="searchType"
                v-model="searchType"
                v-on="smiles.length ? { change: updateSearch } : {}" />
            </div>
            <div class="column">
              <label for="similarity"><b>Similarity search (Tanimoto)</b></label>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns is-gapless">
            <div v-for="t in similarityThresholds" :key="t.value" class="column">
              <div class="control mb-2">
                <label class="radio">
                  <input
                    type="radio"
                    :id="t.id"
                    :value="t.value"
                    :disabled="searchType !== 'similarity'"
                    name="threshold"
                    v-model="threshold"
                    v-on="smiles.length ? { change: updateSearch } : {}" />
                  {{ t.label }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/api/config';
import { dataService } from '@/api';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabStructure',
  mixins: [SearchPanelMixin],
  mounted() {
    const ketcherFrame = this.$refs.ifKetcher;
    if ('contentDocument' in ketcherFrame) {
      this.ketcherWindow = ketcherFrame.contentWindow;
      this.ketcherLoaded = true;
    }
    else {
      this.ketcherError = true;
    }
  },
  methods: {
    async updateSmiles() {
      // TODO: Replace this with https://github.com/epam/ketcher/issues/266 when
      // upgrading to Ketcher 2.2.0 (DS-2961) and await ketcher.getSmiles(true)
      // becomes available. That 'true' gets the Extended SMILES but it will require
      // /api/ketcher/indigo/convert to be added to our API in KetcherController.
      const molfile = await this.ketcherWindow.ketcher.getMolfile();
      const result = await dataService.getExtendedSmilesFromMolFile(molfile);
      if (result.Data && result.Data.smiles) {
        this.smiles = result.Data.smiles;
        this.updateSearch();
      }
    },
  },
  data() {
    return {
      ketcherWindow: null,
      ketcherLoaded: false,
      ketcherError: false,
      ketcherFramePath: `${this.$router.options.base}ketcher/ketcher.html?api_path=${API}/ketcher`,
      similarityThresholds: [
        { label: '>=99%', value: '0.99', id: 'threshold-99' },
        { label: '>=95%', value: '0.95', id: 'threshold-95' },
        { label: '>=90%', value: '0.90', id: 'threshold-90' },
        { label: '>=80%', value: '0.80', id: 'threshold-80' },
        { label: '>=70%', value: '0.70', id: 'threshold-70' },
      ],
    }
  },
  computed: {
    isLoading() {
      return this.IsCompoundsLoading || this.IsArticlesLoading;
    },
    ...mapState('compounds', {
      IsCompoundsLoading: state => state.ServiceStatus.AwaitingResponse,
    }),
    ...mapState('articles', {
      IsArticlesLoading: state => state.ServiceStatus.AwaitingResponse,
    }),
    ...mapFields('search', {
      smiles: 'SearchParams.structure.value',
      searchType: 'SearchParams.structure.searchType',
      threshold: 'SearchParams.structure.threshold',
    }),
  },
  watch: {
    smiles(newValue) {
      // Clear Ketcher canvas if search pill is deleted
      if (!newValue.length) {
        this.ketcherWindow.ketcher.editor.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_bulma-modifiers';
$editor-visibility-breakpoint: 930px;
$editor-height: 480px;
#ifKetcher {
  width: 100%;
  height: $editor-height;
  padding: 0.5rem;
  overflow: hidden;
}
#btn-update-structure-search {
  display: none;
  @media only screen and (min-width: $editor-visibility-breakpoint) {
    display: inline-flex;
  }
}
#structure-panel-canvas {
  height: $editor-height;
  position: relative;
}
#structure-panel-canvas-status {
  height: 100%;
}
#structure-panel-canvas-loading {
  // No need to show a progress bar if the viewport isn't 
  // wide enough for the editor
  display: none;
  @media only screen and (min-width: $editor-visibility-breakpoint) {
    display: block;
  }
}
#structure-panel-canvas-warning {
  @media only screen and (min-width: $editor-visibility-breakpoint) {
    display: none;
  }
}
#structure-panel-canvas-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: none;
  @media only screen and (min-width: $editor-visibility-breakpoint) {
    display: block;
  }
}
#structure-panel-options {
  display: none;
  @media only screen and (min-width: $editor-visibility-breakpoint) {
    display: block;
  }
}
input[type="radio"] {
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.75rem;
  transform: scale(1.5);
}
label {
  cursor: pointer;
  :disabled & {
    color: #ccc !important;
  }
}
</style>