<template>
  <ul :id="this.compound ? 'compound-record-articles' : 'article-record-articles'"
    :aria-labelledby="this.compound ? 'compound-tab-articles' : 'article-tab-articles'">
    <li v-for="article in articles" :key="article.articleId">
      <article class="mb-5">
        <router-link
          class="is-size-4 has-text-weight-bold hover-underline"
          :to="{ name: 'Article', params: { id: article.articleId }}"
          v-html="article.title"
        ></router-link>
        <p v-if="article.authors">
          {{ article.authors }}
        </p>
        <p v-if="article.journalTitle" class="mb-1">
          <ArticleCitation :article="article" />
        </p>
        <DoiLink
          v-if="article.digitalObjectIdentifier"
          :doi="article.digitalObjectIdentifier"
          class="is-size-6"
        />
      </article>
    </li>
  </ul>
</template>

<script>
import ArticleCitation from '@/components/shared/article-citation';
import DoiLink from '@/components/shared/doi-link';
export default {
  name: 'TabArticles',
  props: {
    // The article record page passes in an article prop, and the compound
    // record page passes in a compound prop
    article: {
      type: Object,
      required: false,
    },
    compound: {
      type: Object,
      required: false,
    },
  },
  computed: {
    // This component is used by the 'Articles' tab on the compound record page
    // and the 'Related articles' tab on the article record page, so the articles
    // object needs to be computed accordingly
    articles() {
      return this.compound
        ? this.compound.articles
        : this.article.relatedArticles;
    },
  },
  components: {
    ArticleCitation,
    DoiLink,
  },
};
</script>
