export default {
  methods: {
    compoundImageUrl(compoundId) {
      return `/file/${compoundId}/${compoundId}_marinlit.png`;
    },
    compoundMolUrl(compoundId) {
      return `/file/${compoundId}/${compoundId}_marinlit.mol`;
    },
    articleCitationRisUrl(articleId) {
      return `/file/${articleId}/${articleId}_marinlit.ris`;
    },
    articleCitationBibUrl(articleId) {
      return `/file/${articleId}/${articleId}_marinlit.bib`;
    },
  },
};
