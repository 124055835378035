<template>
  <a :href="doiLink"
    target="_blank"
    rel="noopener noreferrer"
    class="hover-underline wrap-line"
    :aria-label="`External link to DOI ${doi}`">
    {{ doiLink }}
  </a>
</template>

<script>
export default {
  name: 'DoiLink',
  props: {
    doi: {
      type: String,
      required: true,
      validator: v => v.startsWith('10.'),
    },
  },
  computed: {
    doiLink() {
      return `https://doi.org/${this.doi}`; 
    },
  },
};
</script>
