<template>
  <div class="columns is-marginless is-mobile">
    <a
      v-if="hasMolFile"
      aria-label="Download MOL file"
      :href="this.compoundMolUrl(compoundId)"
      class="column is-narrow is-size-5 hover-underline"
      download
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M 19,20 V 18 H 5 v 2 z M 19,10 H 15 V 4 H 9 v 6 H 5 l 7,7 z"/>
      </svg>
      <span class="ml-1">.mol</span>
    </a>
    <router-link
      tabindex="-1"
      aria-hidden="true"
      class="column is-size-5 has-text-right hover-underline"
      :to="{ name: 'Compound', params: { id: compoundId }}"
    >Details</router-link>
  </div>
</template>

<script>
import urlHelpers from "@/mixins/url-helpers";
export default {
  name: 'CapsuleLinks',
  mixins: [urlHelpers],
  props: {
    compoundId: {
      type: String,
      required: true,
      validator: v => v.startsWith('L')
    },
    hasMolFile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_bulma-modifiers';
a {
  svg {
    fill: $blue;
    vertical-align: middle;
  }
  &:hover, &:focus {
    svg {
      fill: $grey-darker;
    }
  }
}
</style>