<template>
  <span v-html="citation"></span>
</template>

<script>
  export default {
    name: 'Citation',
    props: {
      article: {
        type: Object,
        required: true,
      },
    },
    computed: {
      citation() {
        let citation = '';
        citation += this.article.journalTitle.length ? `<b><i>${this.article.journalTitle}</i></b>, ` : '';
        citation += this.article.articleYear.length ? `${this.article.articleYear}, ` : '';
        citation += this.article.journalVolume.length ? `<b>${this.article.journalVolume}</b>, ` : '';
        citation += this.article.firstPage.length ? this.article.firstPage : '';
        citation += this.article.lastPage.length ? `-${this.article.lastPage}` : '';
        return citation;
      },
    },
  }
</script>
