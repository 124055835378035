<template>
  <img
    :src="imagePath"
    :alt="imageTitle"
    :title="imageTitle"
    :width="size"
    :height="size"
  />
</template>

<script>
import urlHelpers from "@/mixins/url-helpers";
export default {
  name: 'CompoundImage',
  mixins: [urlHelpers],
  computed: {
    imagePath() {
      return this.compound.hasCompoundImage ? this.compoundImageUrl(this.compound.compoundId) : require('@/assets/no-image.png');
    },
    imageTitle() {
      return this.compound.hasCompoundImage ? this.compound.title : '';
    },
  },
  props: {
    compound: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 250
    },
  },
};
</script>
