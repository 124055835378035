import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './modules/auth';
import searchModule from './modules/search';
import compoundsModule from './modules/compounds';
import articlesModule from './modules/articles';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth: authModule,
    search: searchModule,
    compounds: compoundsModule,
    articles: articlesModule,
  },
});
