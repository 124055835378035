<template>
  <div
    :id="this.compound ? 'compound-record-location' : 'article-record-location'"
    :aria-labelledby="this.compound ? 'compound-tab-location' : 'article-tab-location'">
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import GoogleMapsMixin from '@/mixins/google-maps-mixin';
export default {
  name: 'TabLocation',
  mixins: [GoogleMapsMixin],
  props: {
    // The article record page passes in an article prop, and the compound
    // record page passes in a compound prop
    article: {
      type: Object,
      required: false,
    },
    compound: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      precisionLabels: [
        'At',                // QF 1
        'Near',              // QF 2
        'In the region of',  // QF 3
      ],
    }
  },
  computed: {
    // This component is used by the 'Location' tab on the compound and article 
    // record pages, so the locations object needs to be computed accordingly
    locations() {
      return this.compound
        ? this.compound.locations
        : this.article.locations;
    },
    mapConfig() {
      return {
        center: {
          lat: this.locations[0].location.lat,
          lng: this.locations[0].location.lon,
        },
        zoom: 6,
        mapTypeId: 'hybrid',
        streetViewControl: false,
      }
    },
  },
  methods: {
    updateMap() {
      let infoWindow = new this.google.maps.InfoWindow();
      for (const data of this.locations) {
        let myLatLng = new this.google.maps.LatLng(data.location.lat, data.location.lon);
        let marker = new this.google.maps.Marker({
          position: myLatLng,
          map: this.map,
          title: data.name
        });
        (function(marker, data, getMapMarkerContent) {
          window.google.maps.event.addListener(marker, 'click', function() {
            infoWindow.setContent(getMapMarkerContent(data));
            infoWindow.open(this.map, marker);
          });
        })(marker, data, this.getMapMarkerContent);
      }
    },
    getMapMarkerContent(data) {
      return `
        <div>
          ${data.name ? `<p>${this.precisionLabels[data.qf - 1]} ${data.name}</p>` : ''}
          ${data.depth ? `<p>Depth : ${data.depth}m</p>` : ''}
          <p>Latitude : ${data.location.lat}</p>
          <p>Longitude : ${data.location.lon}</p>
        </div>
      `;
    },
  },
}
</script>

<style scoped>
.google-map {
  height: 600px;
  width: 100%;
}
</style>