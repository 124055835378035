<template>
  <div>
    <div v-if="compound" class="v-stretch">
      <PageTitle :title="compound.title" />
      <div class="has-background-white px-4 py-6 v-expand" id="compound-record-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-narrow" id="compound-record-structure">
            <div class="has-text-centered">
              <a v-if="compound.hasCompoundImage" :href="this.compoundImageUrl(compound.compoundId)" @click.prevent="openImageModal">
                <CompoundImage id="img-compound-structure" :compound="compound" :size="500" />
              </a>
              <CompoundImage v-else id="img-compound-structure" :compound="compound" :size="500" />
            </div>
            <div v-if="compound.hasCompoundImage || compound.hasMolFile" class="columns is-mobile is-centered is-multiline">
              <div v-if="compound.hasCompoundImage" class="column is-narrow">
                <a class="button is-medium is-primary" @click="saveFile(compound.compoundId, true)">
                  <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" fill="white" width="24px" height="24px" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M0 0h24v24H0z" fill="none"/><path d="M 19,20 V 18 H 5 v 2 z M 19,10 H 15 V 4 H 9 v 6 H 5 l 7,7 z"/>
                  </svg>
                  <span class="is-sr-only">Download</span> Image (.png)
                </a>
              </div>
              <div v-if="compound.hasMolFile" class="column is-narrow">
                <a class="button is-medium is-primary" :href="this.compoundMolUrl(compound.compoundId)" :download="`${compound.compoundId}-marinlit.mol`">
                  <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" fill="white" width="24px" height="24px" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M0 0h24v24H0z" fill="none"/><path d="M 19,20 V 18 H 5 v 2 z M 19,10 H 15 V 4 H 9 v 6 H 5 l 7,7 z"/>
                  </svg>
                  <span class="is-sr-only">Download</span> Structure (.mol)
                </a>
              </div>
            </div>
          </div>
          <div class="column" id="compound-record-content">
            <div class="columns content is-multiline is-variable is-8">
              <section class="column">
                <h2 class="data-label">Molecular formula</h2>
                <p class="data-value-bold" v-html="compound.molecularFormula"></p>
              </section>
              <section class="column">
                <h2 class="data-label">Exact mass</h2>
                <p class="data-value-bold">{{ compound.molecularWeight }}</p>
              </section>
              <section class="column">
                <h2 class="data-label">Status</h2>
                <p class="data-value-bold">{{ compound.status }}</p>
              </section>
              <section class="column">
                <h2 class="data-label">Compound ID</h2>
                <p class="data-value-bold">{{ compound.compoundId }}</p>
              </section>
              <section class="column" v-if="monomerIdCount">
                <h2 class="data-label">BindingDB</h2>
                <p class="data-value-bold">
                  <a :href="bindingDbUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    :aria-label="`View binding data for ${monomerIdCount} similar molecule${monomerIdCount > 1 ? 's' : ''} on bindingdb.org`">
                    {{ monomerIdCount }} {{ monomerIdCount > 1 ? 'results' : 'result' }}
                  </a>
                </p>
              </section>
            </div>
            <div class="content mb-6">
              <section v-for="(property, index) in compound.properties" :key="index" class="mb-5">
                <table class="table is-bordered is-narrow uv-table">
                  <caption class="data-label has-text-weight-bold has-text-left mb-2" v-html="uvMaxTableHeading(property.name)"></caption>
                  <thead>
                    <tr>
                      <th>UV Value (nm)</th>
                      <th>Log &epsilon;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(detail, index) in property.details" :key="index">
                      <td>{{ detail.value }}</td>
                      <td>{{ detail.logEValue }}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section v-if="compound.inchi" class="mb-5">
                <h3 class="data-label has-text-weight-bold is-inline-block mr-2">Standard InChI</h3>
                <button type="button" aria-label="Copy Standard InChI to clipboard" class="text-button" id="btn-copy-inchi" v-clipboard:copy="compound.inchi" v-clipboard:success="onCopy">
                  {{ clipboardButtonText }}
                </button>
                <p class="wrap-line">{{ compound.inchi }}</p>
              </section>
              <section v-if="compound.inchiKey" class="mb-5">
                <h3 class="data-label has-text-weight-bold is-inline-block mr-2">InChIKey</h3>
                <button type="button" aria-label="Copy InChIKey to clipboard" class="text-button" id="btn-copy-inchikey" v-clipboard:copy="compound.inchiKey" v-clipboard:success="onCopy">
                  {{ clipboardButtonText }}
                </button>
                <p class="wrap-line">{{ compound.inchiKey }}</p>
              </section>
              <section v-if="compound.acdName" class="mb-5">
                <h3 class="data-label has-text-weight-bold is-inline-block mr-2">ACD Name</h3>
                <button type="button" aria-label="Copy ACD Name to clipboard" class="text-button" id="btn-copy-acdname" v-clipboard:copy="compound.acdName" v-clipboard:success="onCopy">
                  {{ clipboardButtonText }}
                </button>
                <p class="wrap-line">{{ compound.acdName }}</p>
              </section>
              <section v-if="compound.note" class="mb-5">
                <h3 class="data-label has-text-weight-bold">Note</h3>
                <p>Compound {{ compound.note }} in original article</p>
              </section>
            </div>
            <div v-if="this.visibleTabs.length" id="compound-record-tabs">
              <div class="tabs is-boxed is-medium">
                <ul role="tablist">
                  <li v-for="tab in visibleTabs" :key="tab.id" :class="{ 'is-active': currentTab === tab.id }">
                    <a href @click.prevent="switchTab(tab.id)"
                      role="tab"
                      :aria-selected="currentTab === tab.id"
                      :aria-controls="`compound-record-${tab.id.toLowerCase()}`"
                      :id="`compound-tab-${tab.id.toLowerCase()}`">
                      {{ tab.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <div role="tabpanel" ref="tabPanel" tabindex="-1">
                <component v-bind:is="currentTabComponent" :compound="compound" @switchTab="switchTab" />
              </div>
            </div>
          </div>
          <div class="modal" :class="{ 'is-active': imageModalVisible }">
            <div class="modal-background" @click="closeImageModal"></div>
            <div class="modal-content">
              <div class="image is-square">
                <CompoundImage :compound="compound" :size="600" />
              </div>
            </div>
            <button type="button" class="modal-close is-large" aria-label="Close" @click="closeImageModal"></button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="error" class="v-stretch">
      <PageTitle title="Error" />
      <RecordError type="compound" :error="error" class="v-expand" />
    </div>
  </div>
</template>

<script>
import { dataService } from '@/api';
import urlHelpers from "@/mixins/url-helpers";
import { TabArticles, TabLocation, TabChShifts, TabSpectrum, TabHsqc } from '@/components/compound-record';
import PageTitle from '@/components/shared/page-title';
import CompoundImage from '@/components/shared/compound-image';
import RecordError from '@/components/shared/record-error';
import { saveAs } from 'file-saver'

export default {
  name: 'Compound',
  metaInfo () {
    return {
      title: this.compound ? this.compound.plainTextTitle
        : this.error ? this.error
        : 'Loading...'
    }
  },
  mixins: [urlHelpers],
  data() {
    return {
      compound: null,
      error: null,
      imageModalVisible: false,
      clipboardButtonText: 'copy',
      clipboardButtonTextActive: 'copied!',
      currentTab: 'Articles',
      tabs: [
        {
          id: 'Articles',
          title: 'Articles',
          visible: false,
          visibilityKey: 'articles',
        },
        {
          id: 'Location',
          title: 'Location',
          visible: false,
          visibilityKey: 'locations',
        },
        {
          id: 'ChShifts',
          title: 'Chemical shifts',
          visible: false,
          visibilityKey: 'nmrData',
        },
        {
          id: 'Spectrum',
          title: '13C spectrum',
          visible: false,
          visibilityKey: 'nmrData',
        },
        {
          id: 'Hsqc',
          title: 'HSQC spectrum',
          visible: false,
          visibilityKey: 'nmrData',
        },
      ],
    };
  },
  async created() {
    await this.fetchCompound();
  },
  methods: {
    async fetchCompound() {
      const response = await dataService.getSingleCompound(this.$route.params.id);
      if (typeof response !== 'object') {
        this.error = 404;
      }
      else {
        this.compound = response;
        this.setTabVisibility();
        this.activateFirstVisibleTab();
      }
    },
    setTabVisibility() {
      for (var i = 0; i < this.tabs.length; i++) {
        const key = this.compound[this.tabs[i].visibilityKey];
        this.tabs[i].visible = key && key.length;
      }
    },
    activateFirstVisibleTab() {
      if (this.visibleTabs.length) {
        this.currentTab = this.tabs.find(tab => tab.visible).id;
      }
    },
    switchTab(id) {
      this.currentTab = id;
      // Move focus into tab panel whenever a tab is selected, so that
      // keyboard users don't have to tab through other tabs to reach
      // the content. A short wait ensures that the correct tab panel
      // is announced rather than the previously-visible tab panel.
      setTimeout(() => this.$refs.tabPanel.focus(), 100);
    },
    uvMaxTableHeading(solvent) {
      return solvent ? `UV max (${solvent})` : 'UV max';
    },
    openImageModal() {
      this.imageModalVisible = true;
    },
    closeImageModal() {
      this.imageModalVisible = false;
    },
    onCopy(event) {
      event.trigger.textContent = this.clipboardButtonTextActive;
      setTimeout(() => {
        event.trigger.textContent = this.clipboardButtonText;
      }, 1000);
    },
    saveFile(id, img) {
      let url = img ? this.compoundImageUrl(id) : this.compoundMolUrl(id);
      let fileName = img ? `${id}-marinlit.png` : `${id}-marinlit.mol`;
      saveAs(url, fileName)
    }
  },
  computed: {
    monomerIdCount() {
      return this.compound.monomerIds.length;
    },
    bindingDbUrl() {
      return `https://www.bindingdb.org/bind/searchby_monomerids.jsp?monomerids=${this.compound.monomerIds.join(',')}`;
    },
    currentTabComponent: function() {
      return 'Tab' + this.currentTab;
    },
    visibleTabs: function() {
      return this.tabs.filter(tab => tab.visible);
    },
  },
  components: {
    PageTitle,
    CompoundImage,
    TabArticles,
    TabLocation,
    TabChShifts,
    TabSpectrum,
    TabHsqc,
    RecordError,
  },
};
</script>
