<template>
  <ul class="column" aria-label="Search parameters">
    <SearchPill
      v-for="(param, id) in singleSearchParameters"
      :key="id"
      :formatterKey="id"
      :label="param.label"
      :value="param.value"
      :showPill="!!param.value"
      :variance="param.variance"
      @remove="removePill(id)" />
    <SearchPill
      v-for="(uvMax, index) in uvMaxSearchParameters"
      :key="`uvMax${index}`"
      formatterKey="uvMax"
      label="UV maxima"
      :value="uvMax.value"
      :showPill="!!uvMax.value"
      :variance="uvMax.variance"
      @remove="removeMultiRowPill('uvMax', index)" />
    <SearchPill
      v-for="(shift, index) in carbonNmrSearchParameters"
      :key="`carbonNmr${index}`"
      formatterKey="nmr"
      label="13C NMR"
      :value="shift.value"
      :showPill="!!shift.value"
      :variance="shift.variance"
      :protons="shift.protons"
      @remove="removeMultiRowPill('carbonNmr', index)" />
    <SearchPill
      v-for="(shift, index) in protonNmrSearchParameters"
      :key="`protonNmr${index}`"
      formatterKey="nmr"
      label="1H NMR"
      :value="shift.value"
      :showPill="!!shift.value"
      :variance="shift.variance"
      @remove="removeMultiRowPill('protonNmr', index)" />
    <SearchPill
      v-for="(taxonomy, index) in taxonomySearchParameters"
      :key="`taxonomy${index}`"
      formatterKey="taxonomy"
      label="Taxonomy"
      :value="taxonomy"
      :showPill="!!taxonomy"
      @remove="removeMultiRowPill('taxonomy', index)" />
    <SearchPill
      v-for="(author, index) in authorSearchParameters"
      :key="`author${index}`"
      label="Author"
      :value="`${author.firstName} ${author.familyName}`"
      :showPill="!!author.familyName"
      @remove="removeMultiRowPill('author', index)" />
    <SearchPill
      v-for="(category, index) in categorySearchParameters"
      :key="`category${index}`"
      label="Category"
      :value="category.label"
      :showPill="!!category"
      @remove="removeMultiRowPill('category', index)" />
  </ul>
</template>

<script>
import SearchPill from "@/components/toolbar/search-pill";
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'SearchPills',
  methods: {
    ...mapActions('search', [
      'removeSearchParameterValueAction',
      'removeMultiRowSearchParameterAction',
    ]),
    updateSearch() {
      this.$emit('updateSearch');
    },
    async removePill(id) {
      this.removeSearchParameterValueAction(id);
      await this.updateSearch();
    },
    async removeMultiRowPill(key, index) {
      this.removeMultiRowSearchParameterAction({ key, index });
      await this.updateSearch();
    },
  },
  computed: {
    ...mapGetters('search', [
      'singleSearchParameters',
      'uvMaxSearchParameters',
      'carbonNmrSearchParameters',
      'protonNmrSearchParameters',
      'taxonomySearchParameters',
      'authorSearchParameters',
      'categorySearchParameters',
    ]),
  },
  components: {
    SearchPill,
  }
}
</script>
