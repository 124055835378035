<template>
  <div id="taxonomy-container" class="px-5 py-5">
    
    <!-- TAXONOMY BUILDER -->
    <fieldset id="fieldset-taxonomy">

      <!-- Required by https://www.w3.org/TR/WCAG20-TECHS/H71.html -->
      <legend class="is-sr-only">{{ legend }}</legend>

      <p class="search-panel-guidance mb-5">
        Select the taxonomic name required from one or more levels and 
        press <i>Add to search</i>. To limit your search to organisms 
        that produced new natural products, tick <i>Source of compound</i>.
      </p>

      <div class="columns is-marginless is-vcentered mb-5">
        <div
          class="column is-8-tablet is-7-desktop taxonomy-builder px-4 py-4"
          :class="{ 'is-valid': isValidTaxonomyBuilder }">
          <div
            v-for="(taxonomy, key, index) in taxa" :key="key"
            class="columns is-vcentered mb-1 input-text-horizontal">
            <div class="column is-3 has-text-right-tablet">
              <label class="label" :for="key">{{ taxonomy.label }}</label>
            </div>
            <div class="column">
              <v-select
                v-model="builderSelections[index]"
                :ref="key"
                :inputId="key"
                :filterable="false"
                :options="taxonomy.options"
                @search="debouncedGetTaxonomySuggestions"
                @search:blur="focusedList = ''"
                @search:focus="focusedList = key">
                <template #list-footer>
                  <li v-if="taxonomy.options.length < taxonomy.count" class="help is-italic has-text-centered pt-3 pb-2">
                    Showing {{ taxonomy.options.length }} of {{ taxonomy.count.toLocaleString() }} suggestions
                    <br />
                    Keep typing...
                  </li>
                </template>
              </v-select>
            </div>
          </div>
          <div class="columns">
            <div class="column is-offset-3">
              <label class="checkbox">
                <input type="checkbox" v-model="builderSelections[6]" />
                Source of compound
              </label>
            </div>
          </div>
        </div>
        <div class="column has-text-centered-mobile">
          <button
            type="button"
            class="button is-primary is-rounded"
            :disabled="!isValidTaxonomyBuilder"
            :id="isEditMode ? 'btn-update-taxonomy' : 'btn-add-taxonomy'"
            v-on="{ click: isEditMode ? updateTaxonomyRow : addTaxonomyRow }">
            {{ isEditMode ? 'Update row' : 'Add to search' }}
          </button>
        </div>
      </div>
    </fieldset>
    
    <!-- TAXONOMY TABLE -->
    <div class="columns" v-if="!!taxonomySearchParameters.length">
      <div id="table-taxonomy" class="column table-container">
        <table class="table is-bordered is-fullwidth is-narrow taxonomy-table">
          <thead>
            <tr>
              <th v-for="rank in taxa" :key="rank.id">
                <span :title="rank.label">{{ rank.label }}</span>
              </th>
              <th>
                <span title="Source of compound">Source</span>
              </th>
              <th>
                <!-- Empty column heading for edit and delete buttons -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rank, index) in taxonomySearchParameters" :key="index"
              :class="{
                'highlight-row': index === editModeRowIndex,
                'has-text-grey-light': isEditMode && index !== editModeRowIndex
              }">
              <!-- Taxonomic ranks -->
              <td v-for="(n, i) in 6" :key="i">
                <span :title="rank[i]">{{ rank[i] }}</span>
              </td>
              <!-- Source of compound -->
              <td>
                <span>{{ rank[6] ? 'Yes' : '' }}</span>
              </td>
              <!-- Edit or remove table row -->
              <td class="has-text-centered">
                <a role="button" 
                   class="edit-row-button mx-1 my-1"
                  :class="{ 'disabled': isEditMode }"
                  :id="`btn-edit-taxonomy${index}`"
                  href @click.prevent="editTaxonomyRow(index)"
                  :title="`Edit row ${index + 1}`"
                  :aria-label="`Edit row ${index + 1}`">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#cecece" width="24" height="24" aria-hidden="true">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                  </svg>
                </a>
                <button
                  type="button"
                  class="delete is-medium mx-1 my-1"
                  :id="`btn-delete-taxonomy${index}`"
                  :title="`Remove row ${index + 1}`"
                  @click="removeTaxonomyRow(index)"
                  :disabled="isEditMode">
                  Remove row {{ index + 1 }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { dataService } from '@/api';
import { DELIMITER } from '@/api/config';
import { debounce } from 'lodash';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabTaxonomy',
  mixins: [SearchPanelMixin],
  data() {
    return {
      taxa: {
        'phylum': { label: 'Phylum', options: [], count: 0 },
        'class': { label: 'Class', options: [], count: 0 },
        'order': { label: 'Order', options: [], count: 0 },
        'family': { label: 'Family', options: [], count: 0 },
        'genus': { label: 'Genus', options: [], count: 0 },
        'species': { label: 'Species', options: [], count: 0 },
      },
      focusedList: '',
      builderSelections: [null, null, null, null, null, null, false],
      editModeRowIndex: null,
    };
  },
  computed: {
    ...mapGetters('search', [
      'taxonomySearchParameters'
    ]),
    isValidTaxonomyBuilder() {
      // At least one of the six taxonomic ranks must contain a value
      return !this.builderSelections.every(s => s === null || s === true || s === false);
    },
    isEditMode() {
      return this.editModeRowIndex !== null;
    },
  },
  methods: {
    ...mapActions('search', [
      'addTaxonomyRowAction',
      'updateTaxonomyRowAction',
      'removeTaxonomyRowAction',
    ]),
    async getTaxonomySuggestions(search, loading) {
      // Need to access v-select component directly for now
      // https://github.com/sagalbot/vue-select/issues/492
      const activeList = this.focusedList ? this.$refs[this.focusedList][0] : null;
      if (activeList) {
        search = search || activeList.search;
        loading = loading || activeList.toggleLoading;
        const taxonomySelections = [...this.builderSelections.slice(0, 6)].join(DELIMITER);
        loading(true);
        const result = await dataService.getTaxonomySuggestions(
          encodeURIComponent(activeList.inputId),
          encodeURIComponent(search.trim()),
          encodeURIComponent(taxonomySelections));
        loading(false);
        if (result.Data) {
          this.taxa[result.Data.rank].options = result.Data.results;
          this.taxa[result.Data.rank].count = result.Data.numberOfResults;
        }
      }
    },
    debouncedGetTaxonomySuggestions: debounce(async function(search, loading) {
      await this.getTaxonomySuggestions(search, loading);
    }, 400),
    addTaxonomyRow() {
      this.addTaxonomyRowAction(this.builderSelections);
      this.resetTaxonomyBuilder();
      this.updateSearch();
    },
    editTaxonomyRow(index) {
      if (!this.isEditMode) {
        this.resetTaxonomyBuilder();
        this.editModeRowIndex = index;
        // Clone it otherwise the table updates before we're ready
        this.builderSelections = [...this.taxonomySearchParameters[index]];
      }
    },
    updateTaxonomyRow() {
      this.updateTaxonomyRowAction({ index: this.editModeRowIndex, value: this.builderSelections });
      this.resetTaxonomyBuilder();
      this.updateSearch();
    },
    removeTaxonomyRow(index) {
      this.removeTaxonomyRowAction(index);
      this.updateSearch();
    },
    resetTaxonomyBuilder() {
      this.editModeRowIndex = null;
      this.builderSelections = [null, null, null, null, null, null, false];
    },
  },
  watch: {
    // Fetch taxonomy suggestions immediately on list focus
    async focusedList() {
      if (this.focusedList.length) {
        await this.getTaxonomySuggestions();
      }
    },
    // Removing a taxonomy search pill changes the indices of the table
    // rows. When this happens, we need to cancel edit mode, otherwise
    // the update action could change the wrong table row.
    taxonomySearchParameters() {
      if (this.isEditMode) {
        this.resetTaxonomyBuilder();
      }
    }
  },
};
</script>
