import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueClipboard from 'vue-clipboard2';
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta';
import vSelect from 'vue-select';
import OpenIndicator from '@/components/forms/vselect-open-indicator.vue';

Vue.use(VueGtm, {
  id: 'GTM-MS44Q9B',
  enabled: true,
  debug: process.env.NODE_ENV !== 'production',
  vueRouter: router
});
Vue.use(VueMeta);
Vue.use(VueClipboard);
vSelect.props.components.default = () => ({
  OpenIndicator,
  Deselect: { render: createElement => createElement('span', { class: 'delete is-medium', })},
});
Vue.component('v-select', vSelect);
Vue.config.productionTip = false;

(async() => {
  await store.dispatch('auth/startup')
    .then(() => {
      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    })
    .catch(err => {
      console.error(err);
      document.getElementById('message').innerHTML = errorMessage;
      document.querySelectorAll('.ani-rsc-spin').forEach(x => x.classList.add('ani-stop'));
    });
})();

const errorMessage = `
  <b class="is-size-5">We’re sorry.</b><br>
  MarinLit is temporarily unavailable. Please try again in a few minutes.<br>
  If this problem continues please <a href="https://www.rsc.org/locations-contacts/contact-us/journals-books-databases/#technical-support">contact our Technical Support Team</a>.
`;