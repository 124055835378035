<template>
  <ul id="article-record-compounds" class="columns is-multiline" aria-labelledby="article-tab-compounds">
    <li v-for="compound in article.substances" :key="compound.compoundId" class="column is-full-mobile is-full-tablet is-full-desktop is-half-widescreen">
      <article>
        <router-link :to="{ name: 'Compound', params: { id: compound.compoundId }}" class="capsule">
          <div class="columns is-marginless is-gapless">
            <div class="column is-narrow">
              <CompoundImage :compound="compound" :size="200" class="is-block my-1 ml-1 mr-6" />
            </div>
            <div class="column no-min-width">
              <div class="capsule__cell small">
                <h3 class="title is-4 px-3 capsule__title">
                  <span v-html="compound.title"></span>
                </h3>
                <div class="columns is-marginless is-multiline">
                  <div class="column">
                    <p class="data-label">Molecular formula</p>
                    <p class="data-value-bold" v-html="compound.molecularFormula"></p>
                  </div>
                  <div class="column">
                    <p class="data-label">Exact mass</p>
                    <p class="data-value-bold">{{ compound.molecularWeight }}</p>
                  </div>
                  <div class="column">
                    <p class="data-label">Status</p>
                    <p class="data-value-bold">{{ compound.status }}</p>
                  </div>
                </div>
                <p v-if="compound.note" class="data-label px-3 mb-3 pin-to-bottom">
                  Compound {{ compound.note }} in original article
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </article>
    </li>
  </ul>
</template>

<script>
import CompoundImage from '@/components/shared/compound-image';

export default {
  name: 'TabCompounds',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  components: {
    CompoundImage,
  },
}
</script>
