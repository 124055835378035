import { render, staticRenderFns } from "./no-results.vue?vue&type=template&id=4f02f4e7"
import script from "./no-results.vue?vue&type=script&lang=js"
export * from "./no-results.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports