var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rsc-ui"},[_c('footer',{staticClass:"rsc-footer",attrs:{"id":"footer"}},[_c('div',{staticClass:"breadcrumbs"},[_c('div',{staticClass:"container"},[_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/footer/chevron-right-light.png"),"width":"8","height":"8","alt":""}}),_c('router-link',{attrs:{"to":"/"}},[_vm._v("MarinLit")])],1)])])]),_vm._m(3),_c('div',{staticClass:"baseline"},[_c('div',{staticClass:"container"},[_vm._m(4),_c('div',{staticClass:"copyright"},[_vm._v(" © Royal Society of Chemistry "+_vm._s(_vm.currentYear)+" "),_c('br'),_vm._v("Registered charity number: 207890 ")])])]),_c('hr',{staticClass:"clear"}),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"extra"},[_c('a',{attrs:{"href":"https://www.rsc.org/"}},[_vm._v("rsc.org")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"extra"},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/footer/chevron-right-light.png"),"width":"8","height":"8","alt":""}}),_c('a',{attrs:{"href":"https://www.rsc.org/journals-books-databases/"}},[_vm._v("Journals, books & databases")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"extra"},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/footer/chevron-right-light.png"),"width":"8","height":"8","alt":""}}),_c('a',{attrs:{"href":"https://www.rsc.org/journals-books-databases/databases-literature-updates/"}},[_vm._v("Databases & literature updates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav",attrs:{"id":"pnlSiteMap"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"rsc-logo"},[_c('img',{attrs:{"src":require("@/assets/footer/rsc-logo-rev.svg"),"alt":"Royal Society of Chemistry homepage","width":"340","height":"85"}})]),_c('div',{staticClass:"links"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/"}},[_vm._v("Home")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/about-us/"}},[_vm._v("About us")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/membership-and-community/"}},[_vm._v("Membership & professional community")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/campaigning-outreach/"}},[_vm._v("Campaigning & outreach")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/journals-books-databases/"}},[_vm._v("Journals, books & databases")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/teaching-and-learning/"}},[_vm._v("Teaching & learning")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/news-events/"}},[_vm._v("News & events")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/locations-contacts/"}},[_vm._v("Locations & contacts")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/careers/"}},[_vm._v("Careers")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/awards-funding/"}},[_vm._v("Awards & funding")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/advertise/"}},[_vm._v("Advertise")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/help-legal/"}},[_vm._v("Help & legal")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/help-legal/legal/privacy/"}},[_vm._v("Privacy policy")])]),_c('li',[_c('a',{attrs:{"href":"https://www.rsc.org/help-legal/legal/terms-conditions/"}},[_vm._v("Terms & conditions")])])])]),_c('hr',{staticClass:"clear"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-icons"},[_c('a',{staticClass:"facebook",attrs:{"href":"https://www.facebook.com/RoyalSocietyofChemistry"}},[_c('img',{attrs:{"src":require("@/assets/footer/facebook-128.png"),"height":"32","width":"32","alt":"Facebook"}})]),_c('a',{staticClass:"twitter",attrs:{"href":"https://twitter.com/RoySocChem"}},[_c('img',{attrs:{"src":require("@/assets/footer/twitter-128.png"),"height":"32","width":"32","alt":"Twitter"}})]),_c('a',{staticClass:"linkedin",attrs:{"href":"https://www.linkedin.com/company/23105"}},[_c('img',{attrs:{"src":require("@/assets/footer/linkedin-128.png"),"height":"32","width":"32","alt":"LinkedIn"}})]),_c('a',{staticClass:"youtube",attrs:{"href":"https://www.youtube.com/user/wwwRSCorg"}},[_c('img',{attrs:{"src":require("@/assets/footer/youtube-128.png"),"height":"32","width":"32","alt":"YouTube"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rsc-onetrust-cookie-footer"},[_c('div',{attrs:{"id":"rsc-onetrust-cookie-footer-non-br"}},[_vm._v("This website collects cookies to deliver a better user experience. "),_c('span',{attrs:{"id":"rsc-onetrust-cookie-footer-global"}},[_vm._v(" See how this site uses "),_c('a',{attrs:{"href":"/cookies"}},[_vm._v("Cookies")]),_vm._v(". ")]),_c('span',{staticStyle:{"display":"none"},attrs:{"id":"rsc-onetrust-cookie-footer-ca"}},[_c('a',{attrs:{"href":"/cookies"}},[_vm._v("Do not sell my personal data")]),_vm._v(". ")])]),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"rsc-onetrust-cookie-footer-br"}},[_vm._v(" Este site coleta cookies para oferecer uma melhor experiência ao usuário. "),_c('span',[_vm._v(" Veja como este site usa "),_c('a',{attrs:{"href":"/cookies"}},[_vm._v("Cookies")]),_vm._v(". ")])])])
}]

export { render, staticRenderFns }