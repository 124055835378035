<template>
  <div class="column is-narrow">
    <button
      type="button"
      @click="toggle"
      class="button pr-2 mb-2 is-info has-text-weight-bold"
      id="btn-search-toggle"
      aria-controls="search-panel"
      :aria-label="`Search. ${active ? 'Close' : 'Open'} panel`"
      :aria-expanded="active ? 'true' : 'false'"
    >
      Search
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" class="level-item" aria-hidden="true">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path
          :d="active 
            ? 'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'
            : 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchToggle',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('togglePanel', !this.active);
    },
  },
};
</script>
