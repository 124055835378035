import {
  SET_SEARCH_PANEL_VISIBILITY,
  UPDATE_SEARCH_RESULT_COUNTS,
  MERGE_SEARCH_PARAMETER_VALUES,
  REMOVE_SEARCH_PARAMETER_VALUE,
  UPDATE_SEARCH_PARAMETER_VALUE,
  ADD_MULTI_ROW_SEARCH_PARAMETER,
  UPDATE_MULTI_ROW_SEARCH_PARAMETER,
  REMOVE_MULTI_ROW_SEARCH_PARAMETER,
} from '../mutation-types';
import { getField, updateField } from 'vuex-map-fields';
import { merge, omit, pickBy } from 'lodash';
import { apiHelper } from '@/api';
import router from '@/router';

export default {

  namespaced: true,

  // STATE
  state: {
    IsSearchPanelVisible: false,
    SearchResultCounts: {
      'articles': 0,
      'compounds': 0,
    },
    
    // Simple parameters (i.e. label/value pair) are handled automatically 
    // by the URL encoder and decoder in /api, but multi-part, multi-row 
    // and other special parameters like taxonomy must be handled individually 
    // in both, and also mapped and formatted in the search panels and pills.
    
    SearchParams: {
      // QUICK SEARCH
      's':                { 'label': 'Quick search', 'value': '' },
      // STRUCTURE
      'structure':        { 'label': 'SMILES',
                            'value': '',
                            'searchType': 'substructure',
                            'substructure': '',
                            'exact': 'strict',
                            'similarity': 'tanimoto',
                            'threshold': '0.99', },
      // COMPOUND PROPERTIES
      'exactMass':        { 'label': 'Exact mass', 'value': '', 'variance': '0.1' },
      'molecularFormula': { 'label': 'Molecular formula', 'value': '' },
      'uvMax':            [ { 'value': '', 'variance': '' } ],
      'compoundName':     { 'label': 'Compound name', 'value': '' },
      // FUNCTIONAL GROUP
      // -- sp3 carbons
      'met':              { 'label': 'Total methyl groups', 'value': '' },
      'mets':             { 'label': 'Singlet methyl', 'value': '' },
      'metd':             { 'label': 'Doublet methyl', 'value': '' },
      'mett':             { 'label': 'Triplet methyl', 'value': '' },
      'arome':            { 'label': 'Aromatic methyl', 'value': '' },
      'vnme':             { 'label': 'Vinyl methyl', 'value': '' },
      'acy':              { 'label': 'Acetyl (including acetyl ester and acetyl amide)', 'value': '' },
      'meo':              { 'label': 'Methoxy', 'value': '' },
      'men':              { 'label': 'N-methyl', 'value': '' },
      'mes':              { 'label': 'S-methyl', 'value': '' },
      'mene':             { 'label': 'Total methylene (secondary) carbons', 'value': '' },
      'mine':             { 'label': 'Total methine (tertiary) carbons', 'value': '' },
      // -- sp2 carbons
      'sp2h':             { 'label': 'Total sp2C–H', 'value': '' },
      'cc':               { 'label': 'Total C=C', 'value': '' },
      'vn':               { 'label': 'Vinyl group', 'value': '' },
      'cch':              { 'label': '1,1-disubstituted', 'value': '' },
      'dic':              { 'label': '1,2-disubstituted', 'value': '' },
      'cc3':              { 'label': 'Trisubstituted', 'value': '' },
      // -- sp carbons
      'tlk':              { 'label': 'Total terminal alkynes (–C≡C–H)', 'value': '' },
      // -- C–O bonds
      'cao':              { 'label': 'Total C-O (excluding acids, esters and lactones)', 'value': '' },
      'c2ho':             { 'label': 'Primary C-O (-CH2-O-)', 'value': '' },
      'c1ho':             { 'label': 'Secondary C-O (>CH-O-)', 'value': '' },
      'ch2o2':            { 'label': 'Primary acetal', 'value': '' },
      'cho2':             { 'label': 'Secondary acetal', 'value': '' },
      'co2':              { 'label': 'Tertiary acetal', 'value': '' },
      // -- C=O bonds
      'co':               { 'label': 'Total C=O', 'value': '' },
      'cho':              { 'label': 'Aldehyde (including formyl)', 'value': '' },
      'aco':              { 'label': 'Acid, ester, lactone', 'value': '' },
      'am':               { 'label': 'Amide', 'value': '' },
      // -- C=N & C≡N bonds
      'cn':              { 'label': 'Imine', 'value': '' },
      'nit':              { 'label': 'Nitrile', 'value': '' },
      'initr':            { 'label': 'Isonitrile', 'value': '' },
      // -- Benzene rings
      'ben':              { 'label': 'Total benzenes', 'value': '' },
      'b1':               { 'label': '1-substituted', 'value': '' },
      'b12':              { 'label': '1,2-substituted', 'value': '' },
      'b13':              { 'label': '1,3-substituted', 'value': '' },
      'b14':              { 'label': '1,4-substituted', 'value': '' },
      'b123':             { 'label': '1,2,3-substituted', 'value': '' },
      'b124':             { 'label': '1,2,4-substituted', 'value': '' },
      'b135':             { 'label': '1,3,5-substituted', 'value': '' },
      'b1234':            { 'label': '1,2,3,4-substituted', 'value': '' },
      'b1235':            { 'label': '1,2,3,5-substituted', 'value': '' },
      'b1245':            { 'label': '1,2,4,5-substituted', 'value': '' },
      'b12345':           { 'label': '1,2,3,4,5-substituted', 'value': '' },
      'b1_6':             { 'label': '1,2,3,4,5,6-substituted', 'value': '' },
      // Pyridine rings
      'p2':               { 'label': '2-substituted', 'value': '' },
      'p3':               { 'label': '3-substituted', 'value': '' },
      'p4':               { 'label': '4-substituted', 'value': '' },
      'p23':              { 'label': '2,3-substituted', 'value': '' },
      'p24':              { 'label': '2,4-substituted', 'value': '' },
      'p25':              { 'label': '2,5-substituted', 'value': '' },
      'p26':              { 'label': '2,6-substituted', 'value': '' },
      'p34':              { 'label': '3,4-substituted', 'value': '' },
      'p35':              { 'label': '3,5-substituted', 'value': '' },
      'p234':             { 'label': '2,3,4-substituted', 'value': '' },
      'p235':             { 'label': '2,3,5-substituted', 'value': '' },
      'p236':             { 'label': '2,3,6-substituted', 'value': '' },
      'p245':             { 'label': '2,4,5-substituted', 'value': '' },
      'p246':             { 'label': '2,4,6-substituted', 'value': '' },
      'p345':             { 'label': '3,4,5-substituted', 'value': '' },
      'p2345':            { 'label': '2,3,4,5-substituted', 'value': '' },
      'p2346':            { 'label': '2,3,4,6-substituted', 'value': '' },
      'p2356':            { 'label': '2,3,5,6-substituted', 'value': '' },
      'p2_6':             { 'label': '2,3,4,5,6-substituted', 'value': '' },
      // NMR CHEMICAL SHIFTS
      'carbonNmr':        [ { 'value': '', 'variance': '2', 'protons': '' } ],
      'protonNmr':        [ { 'value': '', 'variance': '0.2' } ],
      // TAXONOMY
      'taxonomy':         [],
      // BIBLIOGRAPHIC
      'articleTitle':     { 'label': 'Article title', 'value': '' },
      'author':           [ { 'familyName': '', 'firstName': '' } ],
      'journal':          { 'label': 'Journal', 'value': '' },
      'year':             { 'label': 'Year', 'value': '' },
      'volume':           { 'label': 'Volume', 'value': '' },
      'page':             { 'label': 'Page', 'value': '' },
      'doi':              { 'label': 'DOI', 'value': '' },
      'category':         [],
      'articleId':        { 'label': 'MarinLit ID (Article)', 'value': '' },
    },
  },

  // MUTATIONS
  mutations: {
    updateField,
    [SET_SEARCH_PANEL_VISIBILITY](state, payload) {
      state.IsSearchPanelVisible = payload;
    },
    [UPDATE_SEARCH_RESULT_COUNTS](state, payload) {
      // Merge because article and compound totals are initialised separately
      merge(state.SearchResultCounts, payload);
    },
    [MERGE_SEARCH_PARAMETER_VALUES](state, payload) {
      // Uses lodash.merge to avoid overwriting other properties
      merge(state.SearchParams[payload.key], payload.value);
    },
    [REMOVE_SEARCH_PARAMETER_VALUE](state, payload) {
      state.SearchParams[payload].value = '';
    },
    [UPDATE_SEARCH_PARAMETER_VALUE](state, payload) {
      state.SearchParams[payload.key].value = payload.value;
    },
    [ADD_MULTI_ROW_SEARCH_PARAMETER](state, payload) {
      state.SearchParams[payload.key].push(payload.value);
    },
    [UPDATE_MULTI_ROW_SEARCH_PARAMETER](state, payload) {
      state.SearchParams[payload.key].splice(payload.index, 1, payload.value);
    },
    [REMOVE_MULTI_ROW_SEARCH_PARAMETER](state, payload) {
      state.SearchParams[payload.key].splice(payload.index, 1);
    },
  },

  // ACTIONS
  actions: {
    setSearchPanelVisibilityAction({ commit }, value) {
      commit(SET_SEARCH_PANEL_VISIBILITY, value);
    },
    updateSearchResultCountsAction({ commit }, value) {
      commit(UPDATE_SEARCH_RESULT_COUNTS, value);
    },
    mergeSearchParameterValuesAction({ commit }, { key, value }) {
      commit(MERGE_SEARCH_PARAMETER_VALUES, { key, value });
    },
    removeSearchParameterValueAction({ commit }, value) {
      commit(REMOVE_SEARCH_PARAMETER_VALUE, value);
    },
    updateSearchParameterValueAction({ commit }, { key, value }) {
      commit(UPDATE_SEARCH_PARAMETER_VALUE, { key, value });
    },
    addEmptyRowSearchParameterAction({ commit }, key) {
      commit(ADD_MULTI_ROW_SEARCH_PARAMETER, {
        key, value: getEmptyMultiRowParameter(key)
      });
    },
    addMultiRowSearchParameterAction({ commit }, { key, value }) {
      commit(ADD_MULTI_ROW_SEARCH_PARAMETER, { key, value });
    },
    removeMultiRowSearchParameterAction({ commit, state }, { key, index }) {
      commit(REMOVE_MULTI_ROW_SEARCH_PARAMETER, { key, index });      
      // Always leave an empty pair of author or UV maxima fields
      // but categories and taxonomy can be empty arrays
      const emptyMultiRowParameter = getEmptyMultiRowParameter(key);
      if (!state.SearchParams[key].length && emptyMultiRowParameter) {
        commit(ADD_MULTI_ROW_SEARCH_PARAMETER, {
          key, value: emptyMultiRowParameter
        });
      }
    },
    addTaxonomyRowAction({ commit }, value) {
      commit(ADD_MULTI_ROW_SEARCH_PARAMETER, { key: 'taxonomy', value });
    },
    updateTaxonomyRowAction({ commit }, { index, value }) {
      commit(UPDATE_MULTI_ROW_SEARCH_PARAMETER, { key: 'taxonomy', index, value });
    },
    removeTaxonomyRowAction({ commit }, index) {
      commit(REMOVE_MULTI_ROW_SEARCH_PARAMETER, { key: 'taxonomy', index });
    },
    async updateAllSearchResultsAction({ dispatch, rootState }) {
      dispatch('updateAddressBarQueryString');
      await dispatch('articles/updateArticleSearchResultsAction', true, { root: true });
      await dispatch('compounds/updateCompoundSearchResultsAction', true, { root: true });
      // Update both counters at once so that screen readers don't announce
      // twice, ie. once for each type of search
      dispatch('updateSearchResultCountsAction', {
        articles: rootState.articles.SearchResultsTotalCount,
        compounds: rootState.compounds.SearchResultsTotalCount,
      });
    },
    updateAddressBarQueryString({ getters }) {
      // Add the search parameters query string to the browser address bar on search results 
      // pages, but without modifying the browser history or using this.$router.replace.
      const currentRoute = router.currentRoute;
      if (currentRoute.name === 'Compounds' || currentRoute.name === 'Articles') {
        const visibleQueryString = getters.searchUrlQueryStringExcludingStructure;
        const noQueryString = router.options.base + currentRoute.path.substring(1); // Strips trailing '?'
        history.replaceState(null, null, visibleQueryString ? `?${visibleQueryString}` : noQueryString);
      }
    },
  },

  // GETTERS
  getters: {
    getField,
    singleSearchParameters: state => pickBy(state.SearchParams, v => typeof(v.value) === 'string'),
    uvMaxSearchParameters: state => state.SearchParams.uvMax,
    carbonNmrSearchParameters: state => state.SearchParams.carbonNmr,
    protonNmrSearchParameters: state => state.SearchParams.protonNmr,
    taxonomySearchParameters: state => state.SearchParams.taxonomy,
    authorSearchParameters: state => state.SearchParams.author,
    categorySearchParameters: state => state.SearchParams.category,
    searchUrlQueryString: state => apiHelper.searchQueryStringBuilder(state.SearchParams),
    searchUrlQueryStringExcludingStructure: state => apiHelper.searchQueryStringBuilder(omit(state.SearchParams, ['structure'])),
  },

}

const getEmptyMultiRowParameter = (key) => {
  switch (key) {
    case 'uvMax':
      return { 'value': '', 'variance': '' };
    case 'carbonNmr':
      return { 'value': '', 'variance': '2', 'protons': '' };
    case 'protonNmr':
      return { 'value': '', 'variance': '0.2' };
    case 'author':
      return { 'familyName': '', 'firstName': '' };
    default:
      return null;
  }
}
