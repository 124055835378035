<template>
  <div class="content" id="compound-record-spectrum" aria-labelledby="compound-tab-spectrum">
    <h3 class="title is-5" id="text-compound-spectrum-title" aria-hidden="true">
      <sup>13</sup>C spectrum for <span v-html="compound.title"></span>
    </h3>
    <p class="is-sr-only">
      The 13C spectrum chart is not accessible at this time.
      <a href @click.prevent="switchTab('ChShifts')">
        Click to access the data table in the Chemical shifts tab.
      </a>
    </p>
    <svg id="chart-compound-spectrum" :viewBox="`0 0 ${totalChartWidth} ${chart.height}`" :style="`max-width: ${totalChartWidth}px`" aria-hidden="true">
      <title>13C spectrum for {{ compound.plainTextTitle }}</title>
      <!-- X axis -->
      <g class="axis">
        <!-- Title -->
        <text :x="totalChartWidth / 2" :y="chart.height - (chart.offset - 55)">
          <tspan text-anchor="middle">13C chemical shifts (ppm)</tspan>  
        </text>
        <!-- Line -->
        <rect
          height="1"
          :width="chart.width"
          :x="chart.padding"
          :y="chart.height - chart.offset">
        </rect>
        <!-- Ticks -->
        <rect
          v-for="n in xRange"
          width="1"
          height="10"
          :x="(chart.width / (xRange.length - 1)) * (n / 10) + chart.padding"
          :y="chart.height - chart.offset"
          :key="`axisTick${n}`">
        </rect>
        <!-- Labels -->
        <text
          v-for="n in xRange"
          :x="(chart.width / (xRange.length - 1)) * ((xRange.length - 1) - n / 10) + chart.padding"
          :y="chart.height - (chart.offset - 25)"
          :key="`axisLabel${n}`">
          <tspan text-anchor="middle">{{ n }}</tspan>  
        </text>
      </g>
      <!-- Peaks -->
      <g class="peaks">
        <rect
          v-for="peak in peaks"
          width="1"
          :height="peak.height"
          :x="peak.position"
          :y="chart.height - peak.height - chart.offset"
          :fill="peak.colour"
          :key="`peak${peak.atomNumber}`">
        </rect>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TabSpectrum',
  data() {
    return {
      chart: {
        width: 880,
        height: 350,
        offset: 65,
        padding: 15,
      }
    }
  },
  props: {
    compound: {
      type: Object,
      required: true
    }
  },
  methods: {
    switchTab(id) {
      this.$emit('switchTab', id);
    },
  },
  computed: {
    totalChartWidth() {
      return this.chart.width + (this.chart.padding * 2);
    },
    nmrDataWithCarbonValues() {
      return this.compound.nmrData.filter(c => c.carbonValue !== null);
    },
    highestCarbonValue() {
      return Math.max(...this.nmrDataWithCarbonValues.map(c => c.carbonValue.value));
    },
    xRange() {
      // Set 140 as the minimum starting point for the x-axis, then adjust upwards
      // in multiples of 10 based on the highest carbon value in the data set.
      const start = Math.max(15, Math.ceil(this.highestCarbonValue / 10) + 1);
      // Returns, e.g. [140, 130 ... 10, 0]
      return Array.from({ length: start }, (v, i) => i * 10).reverse();
    },
    singlePeakHeight() {
      return (this.chart.height - this.chart.offset) / 4;
    },
    peaks() {
      return this.nmrDataWithCarbonValues.map(c => ({
        atomNumber: c.locant,
        colour: ['blue', 'red', 'green', 'black'][c.protons],
        // Calculate (and reverse) x position as a proportion of maximum x-axis value (round negative 13C shift values to 0)
        position: (this.chart.width + this.chart.padding) - (Math.max(0, c.carbonValue.value) / this.xRange[0]) * this.chart.width,
        height: c.protons === 0
          // Zero attached protons is a shorter line...
          ? this.singlePeakHeight / 2
          // ...otherwise stack atoms with the same 13C shift (capped at 4)
          : Math.min(4, this.nmrDataWithCarbonValues.filter(x => x.carbonValue.value === c.carbonValue.value).length) * this.singlePeakHeight
      }));
    },
  },
};
</script>
