import { DELIMITER } from './config';
import store from '@/store';

const importSearchFromQueryString = function (queryStringAsObject) {

  // Get valid search parameters from Vuex store, except for 'structure'
  // as this is deliberately excluded from the visible (browser address 
  // bar) query string.
  const allowedSearchParameters = Object.keys(store.state['search'].SearchParams).filter(k => k !== 'structure');

  // Iterate query string parameters, ignoring unrecognised keys
  for (const [key, value] of Object.entries(queryStringAsObject))
  {
    if (allowedSearchParameters.includes(key)) {
      importSearchParameter(key, value);
    }
  }
}

const importSearchParameter = function(key, value) {
  
  // Cast strings to single-item arrays so that unexpected duplicates can be 
  // ignored later. For example ?s=acetate&s=furanone is not valid because 
  // quick search is a single-use parameter, unlike e.g. author.
  if (typeof value === 'string') {
    value = [value];
  }
  
  switch (key) {
    case 'exactMass':
      importSingleSplitParameter(key, value);
      break;
    case 'uvMax':
    case 'carbonNmr':
    case 'protonNmr':
    case 'taxonomy':
    case 'author':
    case 'category':
      importMultiRowParameter(key, value);
      break;
    default:
      importSingleValueParameter(key, value);
      break;
  }
}

// 1. Single-use parameters with one part, e.g. quick search, molecular formula, compound name etc.
const importSingleValueParameter = function (key, value) {
  // Discard invalid duplicates, e.g. ?s=sulfate&s=acetate
  if (value[0].length) {
    store.dispatch('search/updateSearchParameterValueAction', { key, value: value[0] });
  }
}

// 2. Single-use parameters with multiple parts, i.e. Structure (options) and exact mass (variance)
const importSingleSplitParameter = function (key, value) {
  value = convertParameterToObject(key, value[0]);
  if (value) {
    store.dispatch('search/mergeSearchParameterValuesAction', { key, value });
  }
}

// 3. Multi-row parameters, e.g. UV maxima, NMR shifts, authors, categories and taxonomy
const importMultiRowParameter = function (key, values) {
  
  let valuesWereImported = false;
  for (let value of values) {
    value = convertParameterToObject(key, value);
    if (value) {
      store.dispatch('search/addMultiRowSearchParameterAction', { key, value });
      valuesWereImported = true;
    }
  }
  
  // If any multi-row values were successfully imported, then remove the 
  // default empty row, except for category and taxonomy
  if (valuesWereImported && !['category', 'taxonomy'].includes(key)) {
    store.dispatch('search/removeMultiRowSearchParameterAction', { key, value: 0 });
  }
}

const convertParameterToObject = function (key, parameter) {
  
  const parts = parameter.split(DELIMITER);
  
  switch (key) {
    
    // Exact mass, UV max and 1H NMR each have a value and an optional variance
    case 'exactMass':
    case 'uvMax':
    case 'protonNmr':
      if (parts.length === 2 && parts[0].length) {
        return { 'value': parts[0], 'variance': parts[1] };
      }
      break;

    // 13C NMR data rows each have a value, an optional variance and an optional 
    // number of attached protons
    case 'carbonNmr':
      if (parts.length === 3 && parts[0].length) {
        return { 'value': parts[0], 'variance': parts[1], 'protons': parts[2] };
      }
      break;

    // Author search parameters must include a family name to be valid, but 
    // first name is optional
    case 'author':
      if (parts.length === 2 && parts[0].length) {
        return { 'familyName': parts[0], 'firstName': parts[1] };
      }
      break;

    // Subcategory is optional, category is not. Need to reconstruct label 
    // that's usually provided by the /articles/article-categories API
    case 'category':
      if (parts.length === 2 && parts[0].length) {
        return {
          'category': parts[0],
          'subcategory': parts[1],
          'label': `${parts[1].length ? `${parts[0]} (${parts[1]})` : parts[0]}`,
        };
      }
      break;

    // Taxonomy searches contain at least one of six ranks, plus a boolean 
    // for 'Source of compound' which needs coercing
    case 'taxonomy':
      if (parts.length === 7) {
        parts[6] = parts[6] === 'true' ? true : false;
        if (!parts.every(s => s === '' || s === true || s === false)) {
          return parts;
        }
      }
      break;

    default:
      break;
  }
}

export const urlDecoder = {
  importSearchFromQueryString,
};