import { dataService, apiHelper } from '@/api';
import {
  INITIALISE_LAST_UPDATED_DATE,
  INITIALISE_TOTAL_NUMBER_OF_COMPOUNDS,
  UPDATE_COMPOUND_VIEW_MODE,
  UPDATE_COMPOUND_SORT_MODE,
  UPDATE_COMPOUND_SEARCH_RESULTS,
  UPDATE_COMPOUND_SEARCH_RESULTS_PAGE_NUMBER,
  UPDATE_COMPOUND_LOCATION_RESULTS,
  SET_COMPOUND_SERVICE_WAITING,
  UPDATE_MAP_VISIBLE_BOUNDS,
  UPDATE_COMPOUND_REQUEST_TIMESTAMP,
} from '../mutation-types';

export default {

  // NAMESPACE
  namespaced: true,

  // STATE
  state: {
    SearchResults: [],
    SearchResultsTotalCount: 0,
    SearchResultsPageNumber: 0,
    CompoundLocations: {
      results: [],
      isCompoundResultsLimitReached: false,
      compoundResultsLimit: 0,
    },
    MapVisibleBounds: null,
    ViewMode: 'list',
    SortMode: 'relevance',
    ServiceStatus: {
      AwaitingResponse: false,
      HasError: false,
      Error: null,
    },
    AllCompoundsCount: 0,
    LastUpdatedDate: '',
    RequestTimestamps: {
      Compounds: 0,
      CompoundLocations: 0,
    },
  },

  // MUTATIONS
  mutations: {
    [INITIALISE_LAST_UPDATED_DATE](state, date) {
      state.LastUpdatedDate = date;
    },
    [INITIALISE_TOTAL_NUMBER_OF_COMPOUNDS](state, count) {
      state.AllCompoundsCount = count;
      state.SearchResultsTotalCount = count;
    },
    [UPDATE_COMPOUND_VIEW_MODE](state, viewMode) {
      state.ViewMode = viewMode;
    },
    [UPDATE_COMPOUND_SORT_MODE](state, sortMode) {
      state.SortMode = sortMode;
    },
    [UPDATE_COMPOUND_SEARCH_RESULTS](state, payload) {
      if (payload.searchResults.Data) {
        if (payload.clearExistingResults) {
          state.SearchResults = payload.searchResults.Data.compounds;
        }
        else {
          state.SearchResults.push(...payload.searchResults.Data.compounds);
        }
        state.SearchResultsTotalCount = payload.searchResults.Data.totalNumberOfCompounds;
      }
      else {
        state.SearchResults = [];
        state.SearchResultsTotalCount = 0;
      }
      state.ServiceStatus = payload.searchResults.ServiceStatus;
    },
    [UPDATE_COMPOUND_SEARCH_RESULTS_PAGE_NUMBER](state, pageNumber) {
      state.SearchResultsPageNumber = pageNumber;
    },
    [UPDATE_COMPOUND_LOCATION_RESULTS](state, payload) {
      state.CompoundLocations = payload.compoundLocationResults.Data;
      state.ServiceStatus = payload.compoundLocationResults.ServiceStatus;
    },
    [UPDATE_MAP_VISIBLE_BOUNDS](state, payload) {
      state.MapVisibleBounds = payload;
    },
    [SET_COMPOUND_SERVICE_WAITING](state) {
      state.ServiceStatus.AwaitingResponse = true;
      state.ServiceStatus.HasError = false;
      state.ServiceStatus.Error = '';
    },
    [UPDATE_COMPOUND_REQUEST_TIMESTAMP](state, id) {
      state.RequestTimestamps[id] = (new Date()).getTime();
    },
  },

  // ACTIONS
  actions: {
    initialiseLastUpdatedDate({ commit }, value) {
      commit(INITIALISE_LAST_UPDATED_DATE, value);
    },
    initialiseTotalNumberOfCompounds({ commit, dispatch }, value) {
      commit(INITIALISE_TOTAL_NUMBER_OF_COMPOUNDS, value);
      dispatch('search/updateSearchResultCountsAction', { compounds: value }, { root: true });
    },
    updateCompoundViewModeAction({ commit }, value) {
      commit(UPDATE_COMPOUND_VIEW_MODE, value);
    },
    updateCompoundSortModeAction({ commit }, value) {
      commit(UPDATE_COMPOUND_SORT_MODE, value);
    },
    async updateCompoundSearchResultsAction({ commit, state, rootGetters }, clearExistingResults) {
      commit(SET_COMPOUND_SERVICE_WAITING);
      commit(UPDATE_COMPOUND_REQUEST_TIMESTAMP, 'Compounds');
      const timestamp = state.RequestTimestamps.Compounds;
      commit(UPDATE_COMPOUND_SEARCH_RESULTS_PAGE_NUMBER,
        clearExistingResults ? 0 : state.SearchResultsPageNumber + 1
      );
      const searchResults = await dataService.getCompounds(
        rootGetters['search/searchUrlQueryString'],
        state.SortMode,
        state.SearchResultsPageNumber,
      );
      // Discard stale results
      if (state.RequestTimestamps.Compounds === timestamp) {
        commit(UPDATE_COMPOUND_SEARCH_RESULTS, { searchResults, clearExistingResults });
      }
    },
    async updateCompoundLocationResultsAction({ commit, state, getters, rootGetters }) {
      commit(SET_COMPOUND_SERVICE_WAITING);
      commit(UPDATE_COMPOUND_REQUEST_TIMESTAMP, 'CompoundLocations');
      const timestamp = state.RequestTimestamps.CompoundLocations;
      const compoundLocationResults = await dataService.getCompoundLocations(
        rootGetters['search/searchUrlQueryString'],
        getters.mapVisibleBoundsQueryString,
      );
      if (state.RequestTimestamps.CompoundLocations === timestamp) {
        commit(UPDATE_COMPOUND_LOCATION_RESULTS, { compoundLocationResults });
      }
    },
    async updateMapVisibleBoundsAction({ commit, dispatch }, value) {
      commit(UPDATE_MAP_VISIBLE_BOUNDS, value);
      await dispatch('updateCompoundLocationResultsAction');
    },
  },

  // GETTERS
  getters: {
    mapVisibleBoundsQueryString: state => apiHelper.mapVisibleBoundsQueryStringBuilder(state.MapVisibleBounds),
  },

}
