<template>
  <div class="search-panel-container">
    <div id="search-panel" ref="searchPanel" tabindex="-1" role="search" aria-label="Sitewide">
      <div class="columns is-marginless is-gapless">
        
        <!-- SEARCH TYPES (25% width) -->
        <div id="search-panel-tabs" class="column is-one-quarter-tablet is-one-fifth-widescreen search-types">
          <div class="py-4">
            <ul class="ml-4" role="tablist" aria-orientation="vertical">
              <li v-for="(tab, key) in tabs" :key="key">
                <a href @click.prevent="switchTab(key)"
                  class="py-2 px-4"
                  role="tab"
                  :aria-label="tab.legend"
                  :aria-selected="currentTab === key"
                  :aria-controls="`search-panel-${key.toLowerCase()}`"
                  :class="{ 'active': currentTab === key }"
                  :id="`search-tab-${key.toLowerCase()}`">
                  {{ tab.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- SEARCH FORMS -->
        <div class="column"
          tabindex="-1"
          role="tabpanel"
          ref="searchForm"
          aria-expanded="true"
          :id="`search-panel-${currentTab.toLowerCase()}`"
          :aria-labelledby="`search-tab-${currentTab.toLowerCase()}`">
          <form @submit.prevent ref="searchForm" :id="`search-form-${currentTab.toLowerCase()}`">
            <div class="columns is-marginless is-gapless">
              <div class="column">
                <keep-alive>
                  <component
                    v-if="currentTabComponent !== 'TabStructure'"
                    :is="currentTabComponent"
                    :legend="currentTabLegend"
                    @updateSearch="updateSearch" />
                </keep-alive>
                <!-- Need to use v-show for Structure panel to prevent -->
                <!-- Ketcher iframe element from reloading repeatedly  -->
                <TabStructure
                  v-show="currentTabComponent === 'TabStructure'"
                  :legend="currentTabLegend"
                  @updateSearch="updateSearch" />
              </div>
              <!-- Close search panel -->
              <div v-if="currentTab !== 'Structure'" id="search-panel-close" class="column is-narrow is-hidden-touch">
                <a href @click.prevent="closeSearchPanel" id="btn-search-panel-cross" class="close-button" role="button" aria-label="Close search panel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                  </svg>
                </a>
              </div>
            </div>
            <ResultsButtons @closeSearchPanel="closeSearchPanel"/>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { TabQuick, TabStructure, TabProperties, TabFunctional, TabNMR, TabTaxonomy, TabBibliographic, ResultsButtons } from '@/components/search-panel';
import { mapState } from 'vuex';
export default {
  data: function() {
    return {
      currentTab: 'Quick',
      tabs: {
        'Quick': {
          title: 'Quick search',
          legend: 'Quick search'
        },
        'Structure': {
          title: 'Structure',
          legend: 'Structure search'
        },
        'Properties': {
          title: 'Compound properties',
          legend: 'Compound properties search'
        },
        'Functional': {
          title: 'Functional group',
          legend: 'Functional group search'
        },
        'NMR': {
          title: 'NMR chemical shifts',
          legend: 'NMR search'
        },
        'Taxonomy': {
          title: 'Taxonomy',
          legend: 'Taxonomy search'
        },
        'Bibliographic': {
          title: 'Bibliographic',
          legend: 'Bibliographic search'
        },
      },
    }
  },
  methods: {
    updateSearch() {
      this.$emit('updateSearch');
    },
    switchTab(id) {
      this.currentTab = id;
      // Move focus into form whenever a tab is selected, so that
      // keyboard users don't have to tab through search tabs to
      // reach the form. A short wait ensures that the correct form
      // is announced rather than the previously-visible form.
      setTimeout(() => this.$refs.searchForm.focus(), 100);
    },
    closeSearchPanel() {
      this.$emit('togglePanel', false);
    },
  },
  computed: {
    ...mapState('search', {
      IsSearchPanelVisible: state => state.IsSearchPanelVisible,
    }),
    currentTabComponent() {
      return 'Tab' + this.currentTab;
    },
    currentTabLegend() {
      return this.tabs[this.currentTab].legend;
    },
  },
  watch: {
    IsSearchPanelVisible(isOpen) {
      // Move focus into search panel when it's opened, so that
      // keyboard users don't have to tab past other toolbar
      // controls to reach the search panel.
      if (isOpen) {
        this.$refs.searchPanel.focus();
      }
    },
  },
  components: {
    TabQuick,
    TabStructure,
    TabProperties,
    TabFunctional,
    TabNMR,
    TabTaxonomy,
    TabBibliographic,
    ResultsButtons,
  },
};
</script>
