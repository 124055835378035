// AUTH
export const SET_LOGGED_IN_AS = 'setLoggedInAs';
export const SET_IP_AUTHENTICATION_ERROR = 'setIpAuthenticationError';
export const PURGE_AUTH = 'purgeAuth';

// SEARCH
export const SET_SEARCH_PANEL_VISIBILITY = 'setSearchPanelVisibility';
export const UPDATE_SEARCH_RESULT_COUNTS = 'updateSearchResultCounts';
export const MERGE_SEARCH_PARAMETER_VALUES = 'mergeSearchParameterValues';
export const REMOVE_SEARCH_PARAMETER_VALUE = 'removeSearchParameterValue';
export const UPDATE_SEARCH_PARAMETER_VALUE = 'updateSearchParameterValue';
export const ADD_MULTI_ROW_SEARCH_PARAMETER = 'addMultiRowSearchParameter';
export const UPDATE_MULTI_ROW_SEARCH_PARAMETER = 'updateMultiRowSearchParameter';
export const REMOVE_MULTI_ROW_SEARCH_PARAMETER = 'removeMultiRowSearchParameter';

// COMPOUNDS
export const INITIALISE_LAST_UPDATED_DATE = 'initialiseLastUpdatedDate';
export const INITIALISE_TOTAL_NUMBER_OF_COMPOUNDS = 'initialiseTotalNumberOfCompounds';
export const UPDATE_COMPOUND_VIEW_MODE = 'updateCompoundViewMode';
export const UPDATE_COMPOUND_SORT_MODE = 'updateCompoundSortMode';
export const UPDATE_COMPOUND_LOCATION_RESULTS = 'updateCompoundLocationResults';
export const UPDATE_COMPOUND_SEARCH_RESULTS = 'updateCompoundSearchResults';
export const UPDATE_COMPOUND_SEARCH_RESULTS_PAGE_NUMBER = 'updateCompoundSearchResultsPageNumber';
export const SET_COMPOUND_SERVICE_WAITING = 'setCompoundServiceWaiting';
export const UPDATE_MAP_VISIBLE_BOUNDS = 'updateMapVisibleBounds';
export const UPDATE_COMPOUND_REQUEST_TIMESTAMP = 'updateCompoundRequestTimestamp';

// ARTICLES
export const INITIALISE_TOTAL_NUMBER_OF_ARTICLES = 'initialiseTotalNumberOfArticles';
export const UPDATE_ARTICLE_VIEW_MODE = 'updateArticleViewMode';
export const UPDATE_ARTICLE_SORT_MODE = 'updateArticleSortMode';
export const UPDATE_ARTICLE_SEARCH_RESULTS = 'updateArticleSearchResults';
export const UPDATE_ARTICLE_SEARCH_RESULTS_PAGE_NUMBER = 'updateArticleSearchResultsPageNumber';
export const SET_ARTICLE_SERVICE_WAITING = 'setArticleServiceWaiting';
export const UPDATE_ARTICLE_REQUEST_TIMESTAMP = 'updateArticleRequestTimestamp';
