<template>
  <div class="has-background-white px-5 py-6">
    <div class="content">
      <h1 class="title">Page not found</h1>
      <h2 class="title is-4">Sorry, we couldn’t find the page you were looking for</h2>
      <p class="is-size-5">This could be because:</p>
      <ul>
          <li>The page has been moved, updated or deleted</li>
          <li>You have followed a broken link or typed the address incorrectly</li>
      </ul>
      <p class="is-size-5">Try the search at the top left corner of this page, or one of these links:</p>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/compounds">Compounds</router-link></li>
        <li><router-link to="/articles">Articles</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Page not found'
  },
};
</script>
