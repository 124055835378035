<template>
  <div>
    <ServiceErrorMessage v-if="ServiceStatus.HasError" :error="ServiceStatus.Error" @retry="retry" />
    <div v-else-if="SearchResultsTotalCount > 0" class="px-5 py-5">
      <h1 class="is-sr-only" id="heading-article-search-results">
        {{ SearchResultsTotalCount }} article search results
      </h1>
      <ArticleList :articles="SearchResults" />
      <LoadMoreButton v-show="showLoadMoreButton" :isLoading="ServiceStatus.AwaitingResponse" @click="loadMoreResults" />
    </div>
    <div v-else>
      <NoResultsMessage v-if="!ServiceStatus.AwaitingResponse" searchType="articles" />
    </div>
  </div>
</template>

<script>
import ArticleList from "@/components/article-results/article-list";
import LoadMoreButton from "@/components/shared/load-more-button";
import NoResultsMessage from "@/components/shared/no-results";
import ServiceErrorMessage from "@/components/shared/service-error-message";
import { mapState, mapActions } from "vuex";
export default {
  name: 'Articles',
  metaInfo: {
    title: 'Article search results'
  },
  async created() {
    // Prevent duplicates from being added to search results
    if (!this.SearchResults.length) {
      await this.updateArticleSearchResultsAction(true);
    }
  },
  methods: {
    ...mapActions('articles', [
      'updateArticleSearchResultsAction'
    ]),
    async loadMoreResults() {
      await this.updateArticleSearchResultsAction(false);
    },
    async retry() {
      await this.updateArticleSearchResultsAction(false);
    },
  },
  computed: {
    ...mapState('articles', {
      SearchResults: state => state.SearchResults,
      ServiceStatus: state => state.ServiceStatus,
    }),
    ...mapState('search', {
      SearchResultsTotalCount: state => state.SearchResultCounts.articles,
    }),
    showLoadMoreButton() {
      return this.SearchResults.length < this.SearchResultsTotalCount;
    }
  },
  components: {
    ArticleList,
    LoadMoreButton,
    NoResultsMessage,
    ServiceErrorMessage,    
  }
};
</script>
