<template>
  <div class="help-bubble" v-if="helpTitle || helpText">
    <b v-if="helpTitle" v-html="helpTitle" class="is-block mb-2"></b>
    <p v-if="helpText" v-html="helpText"></p>
  </div>
</template>

<script>
export default {
  name: 'HelpBubble',
  props: {
    helpTitle: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: ''
    },
  },
}
</script>
