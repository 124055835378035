<template>
  <ul class="columns is-multiline" aria-labelledby="heading-compound-search-results">
    <li v-for="compound in compounds" :key="compound.compoundId" class="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen is-2-fullhd">
      <article>
        <div class="capsule">
          <router-link :to="{ name: 'Compound', params: { id: compound.compoundId }}">
            <h2 class="title is-4 px-3 capsule__title capsule__title--grid">
              <span v-html="compound.title"></span>
            </h2>
            <div class="has-text-centered">
              <CompoundImage :compound="compound" loading="lazy" class="px-2 pt-3" />
            </div>
          </router-link>
          <CapsuleLinks :compoundId="compound.compoundId" :hasMolFile="compound.hasMolFile" />
        </div>
      </article>
    </li>
  </ul>
</template>

<script>
import CapsuleLinks from '@/components/shared/capsule-links';
import CompoundImage from '@/components/shared/compound-image';
export default {
  name: 'CompoundGrid',
  props: {
    compounds: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CapsuleLinks,
    CompoundImage,
  }
};
</script>
