<template>
  <fieldset id="fieldset-quick" class="px-5 py-5">

    <!-- Required by https://www.w3.org/TR/WCAG20-TECHS/H71.html -->
    <legend class="is-sr-only">{{ legend }}</legend>

    <div class="columns">
      <div class="column is-three-fifths-tablet is-half-desktop">
        <TextInput
          id="s"
          label="Search"
          v-model="s"
          @input="updateSearch"
        />
      </div>
      <div class="column is-two-fifths-tablet is-offset-1-desktop is-5-desktop mt-5">
        <HelpBubble
          class="mt-1"
          helpTitle="Quick search"
          helpText="Search any text over articles and compounds."
        />
      </div>
    </div>
    <img
      class="is-hidden-mobile ml-3 mb-6"
      width="307" height="87"
      src="@/assets/searches-hint.png"
      alt="More specific searches"
    />
  </fieldset>
</template>

<script>
import SearchPanelMixin from '@/mixins/search-panel-mixin';
import { mapFields } from 'vuex-map-fields';
export default {
  name: 'TabQuick',
  mixins: [SearchPanelMixin],
  computed: {
    ...mapFields('search', {
      s: 'SearchParams.s.value',
    }),
  },
};
</script>
