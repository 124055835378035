import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { API } from './config';

axios.defaults.baseURL = API;

axios.interceptors.response.use(response => response,
  async error => {
    // Store the original request for a retry
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      
      // Set _retry to prevent infinite loops
      originalRequest._retry = true;
      
      // Try IP authentication to get a new JWT
      await store.dispatch('auth/authenticateWithIpAddress');
      
      // IP authentication succeeded so retry original request
      if (store.getters['auth/isAuthenticatedByIpAddress']) {
        return axios(originalRequest);
      }
      
      // Otherwise subscribers must provide their credentials again;
      // preserve redirect path by avoiding multiple logouts, e.g. when
      // article and compound search API requests were both intercepted.
      else if (store.getters['auth/isAuthenticated']) {
        await store.dispatch('auth/logout', router.currentRoute.path);
      }
    }
    return Promise.reject(error);
  }
);

// STARTUP
const boot = async function () {
  try {
    const response = await axios.get('/boot');
    return okResult(response.data);
  } catch (error) {
    return errorResult(error);
  }
}

// AUTHENTICATION (IP or subscriber)
const authenticate = async function (userName, password) {
  try {
    const response = await axios.post('/authenticate', { userName, password });
    let data = parseResponse(response);
    const loginStatus = data;
    return okResult(loginStatus);
  } catch (error) {
    return errorResult(error);
  }
}

// LOG OUT
const logout = async function () {
  try {
    const response = await axios.get('/logout');
    return okResult(response.data);
  } catch (error) {
    return errorResult(error);
  }
}

// COMPOUND RECORD PAGE
const getSingleCompound = async function (id) {
  try {
    const response = await axios.get(`/compounds/${id}`);
    return response.data;
  } catch (error) {
    return error.response.status;
  }
}

// ARTICLE RECORD PAGE
const getSingleArticle = async function (id) {
  try {
    const response = await axios.get(`/articles/${id}`);
    return response.data;
  } catch (error) {
    return error.response.status;
  }
}

// COMPOUND LIST/GRID SEARCH
const getCompounds = async function (searchQueryString, sortBy, pageNo) {
  try {
    const response = await axios.get(`/compounds?${searchQueryString}&SortBy=${sortBy}&PageNo=${pageNo}`);
    let data = parseResponse(response);
    const compounds = data;
    return okResult(compounds);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// COMPOUND MAP SEARCH
const getCompoundLocations = async function (searchQueryString, mapVisibleBoundsQueryString) {
  try {
    const response = await axios.get(`/compounds/map?${searchQueryString}&mvb=${mapVisibleBoundsQueryString}`);
    let data = parseResponse(response);
    const compoundLocations = data;
    return okResult(compoundLocations);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// ARTICLE SEARCH
const getArticles = async function (searchQueryString, sortBy, pageNo) {
  try {
    const response = await axios.get(`/articles?${searchQueryString}&SortBy=${sortBy}&PageNo=${pageNo}`);
    let data = parseResponse(response);
    const articles = data;
    return okResult(articles);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// JOURNAL TITLE SEARCH
const getJournalTitles = async function (searchTerm) {
  try {
    const response = await axios.get(`/articles/journal-titles?s=${searchTerm}`);
    let data = parseResponse(response);
    const journals = data;
    return okResult(journals);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// ARTICLE CATEGORIES
const getArticleCategories = async function () {
  try {
    const response = await axios.get('/articles/article-categories');
    let data = parseResponse(response);
    const categories = data;
    return okResult(categories);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// TAXONOMY SUGGESTIONS
const getTaxonomySuggestions = async function (rank, searchTerm, builderSelections) {
  try {
    const response = await axios.get(`/taxonomy/${rank}?s=${searchTerm}&o=${builderSelections}`);
    let data = parseResponse(response);
    const suggestions = data;
    return okResult(suggestions);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// EXTENDED SMILES
const getExtendedSmilesFromMolFile = async (molfile) => {
  try {
    const response = await axios.post('/ketcher/indigo/smiles', { struct: molfile });
    let data = parseResponse(response);
    const smiles = data;
    return okResult(smiles);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

// TRANSLATE LEGACY IDs, eg. cs000000060062 to L35811
const translateLegacyId = async (markLogicId) => {
  try {
    const response = await axios.get(`/translate-legacy-id/${markLogicId}`);
    let data = parseResponse(response);
    const marinLitId = data;
    return okResult(marinLitId);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const parseResponse = response => {
  if (response.status !== 200) {
    throw Error(response.message);
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== 'object') {
    data = [];
  }
  return data;
}

const okResult = data => {
  return {
    'Data': data,
    'ServiceStatus': {
      'AwaitingResponse': false,
      'HasError': false,
      'Error': null,
    },
  };
};

const errorResult = error => {
  return {
    'Data': null,
    'ServiceStatus': {
      'AwaitingResponse': false,
      'HasError': true,
      'Error': error,
    },
  };
};

export const dataService = {
  boot,
  authenticate,
  logout,
  getSingleCompound,
  getSingleArticle,
  getCompounds,
  getCompoundLocations,
  getArticles,
  getJournalTitles,
  getArticleCategories,
  getTaxonomySuggestions,
  getExtendedSmilesFromMolFile,
  translateLegacyId,
};
