<template>
  <div>
    <div id="toolbar" class="px-4 pt-5 pb-4">
      <div v-if="isAuthenticated" class="columns">
        <SearchAnnouncer />
        <SearchToggle :active="this.IsSearchPanelVisible" @togglePanel="toggleSearchPanel" />
        <SearchPills @updateSearch="updateSearch" />
        <SortByControl v-if="this.$route.meta.sortMode" :mode="this.$route.meta.sortMode" @toggleSort="toggleSortMode" />
        <CompoundViewModes v-if="this.$route.name === 'Compounds'" />
      </div>
      <LoginButton v-else-if="this.$route.name !== 'Login'" />
    </div>
    <SearchPanel v-show="this.IsSearchPanelVisible" @togglePanel="toggleSearchPanel" @updateSearch="debouncedSearch" />
  </div>
</template>

<script>
import LoginButton from '@/components/toolbar/login-button';
import SearchAnnouncer from '@/components/toolbar/search-announcer';
import SearchToggle from '@/components/toolbar/search-toggle';
import SearchPills from '@/components/toolbar/search-pills';
import SortByControl from '@/components/toolbar/sort-by-control';
import CompoundViewModes from '@/components/toolbar/compound-view-modes';
import SearchPanel from '@/components/toolbar/search-panel';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
export default {
  name: 'Toolbar',
  created() {
    window.addEventListener('keydown', this.escapeKeyListener);
  },
  methods: {
    // SEARCH
    ...mapActions('search', [
      'setSearchPanelVisibilityAction',
      'updateAllSearchResultsAction',
    ]),
    async updateSearch() {
      // Search parameters have changed so update all search results
      await this.updateAllSearchResultsAction();
      
      // Only make expensive map results API call when map is in view
      if (this.$route.name === 'Compounds' && this.ViewMode === 'map') {
        await this.updateCompoundLocationResultsAction();
      }
    },
    // COMPOUNDS
    ...mapActions('compounds', [
      'updateCompoundSearchResultsAction',
      'updateCompoundLocationResultsAction',
    ]),
    // ARTICLES
    ...mapActions('articles', [
      'updateArticleSearchResultsAction',
    ]),
    // EMITTED EVENTS
    debouncedSearch: debounce(async function() {
      await this.updateSearch();
    }, 400),
    toggleSearchPanel(show) {
      this.setSearchPanelVisibilityAction(show);
    },
    async toggleSortMode(page) {
      page === 'compounds'
        ? await this.updateCompoundSearchResultsAction(true)
        : await this.updateArticleSearchResultsAction(true);
    },
    // OTHER
    escapeKeyListener(e) {
      if (e.key === "Escape") {
        this.setSearchPanelVisibilityAction(false);
      }
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapState('search', {
      IsSearchPanelVisible: state => state.IsSearchPanelVisible,
    }),
    ...mapState('compounds', {
      ViewMode: state => state.ViewMode,
    }),
  },
  components: {
    LoginButton,
    SearchAnnouncer,
    SearchToggle,
    SearchPills,
    SearchPanel,
    SortByControl,
    CompoundViewModes,
  },
};
</script>
