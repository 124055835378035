<template>
  <div class="m-auto column is-two-thirds-tablet is-half-desktop">
    <div class="rsc-onetrust-cookie-policy box">
      <div class="rsc-onetrust-cookie-settings">
        <!-- OneTrust Cookies Settings button start -->
        <button type="button" id="ot-sdk-btn" class="ot-sdk-show-settings">
          Cookie Settings
        </button>
        <!-- OneTrust Cookies Settings button end -->
      </div>
      <!-- OneTrust Cookies List start -->
      <div id="ot-sdk-cookie-policy"></div>
      <!-- OneTrust Cookies List end -->
    </div>
  </div>
</template>
<script>
export default {
  mounted(){
    setTimeout(function(){
      console.log("initializeCookiePolicyHtml");
      // eslint-disable-next-line no-undef
      OneTrust.initializeCookiePolicyHtml()
    },500);
  },
}
</script>