<template>
  <div class="table-container" id="article-record-taxonomy" aria-labelledby="article-tab-taxonomy">
    <table class="table is-bordered is-fullwidth">
      <caption class="is-sr-only">Taxonomy</caption>
      <thead>
        <tr>
          <th>Phylum</th>
          <th>Class</th>
          <th>Order</th>
          <th>Family</th>
          <th>Genus</th>
          <th>Species</th>
          <th>Source of compound</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(taxonomy, index) in article.taxonomyList" :key="index">
          <td>{{ taxonomy.phylum }}</td>
          <td>{{ taxonomy.class }}</td>
          <td>{{ taxonomy.order }}</td>
          <td>{{ taxonomy.family }}</td>
          <td>{{ taxonomy.genus }}</td>
          <td>{{ taxonomy.species }}</td>
          <td>{{ taxonomy.isSourceOfCompound ? 'Yes' : '' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TabTaxonomy',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>
