<template>
  <div>
    <a v-if="rowIndex === 0"
      class="add-row-button"
      href @click.prevent="addEmptyRowSearchParameterAction(parameterId)"
      role="button"
      :id="`btn-add-row-${parameterId}`"
      :title="addLabel"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="28" height="28" aria-hidden="true">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
      </svg>
    </a>
    <button v-else
      type="button"
      class="delete is-medium delete-row-button"
      :title="removeLabel"
      :id="`btn-delete-row-${parameterId}${rowIndex}`"
      @click="removeMultiRowSearchParameterAction({ index: rowIndex, key: parameterId }); updateSearch();">
      {{ removeLabel }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'AddRemoveRowButtons',
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    parameterId: {
      type: String,
      required: true,
      validator: function(value) {
        return ['uvMax', 'carbonNmr', 'protonNmr', 'author'].indexOf(value) !== -1;
      }
    },
    addLabel: {
      type: String,
      default: 'Add row'
    },
    removeLabel: {
      type: String,
      default: 'Remove row'
    },
  },
  methods: {
    ...mapActions('search', [
      'addEmptyRowSearchParameterAction',
      'removeMultiRowSearchParameterAction',
    ]),
    updateSearch() {
      this.$emit('updateSearch');
    },
  },
}
</script>
