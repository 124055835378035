<template>
  <div class="content" id="compound-record-hsqc" aria-labelledby="compound-tab-hsqc">
    <h3 class="title is-5" id="text-compound-hsqc-title" aria-hidden="true">
      HSQC spectrum for <span v-html="compound.title"></span>
    </h3>
    <p class="is-sr-only">
      The HSQC spectrum chart is not accessible at this time.
      <a href @click.prevent="switchTab('ChShifts')">
        Click to access the data table in the Chemical shifts tab.
      </a>
    </p>
    <svg id="chart-compound-hsqc" :viewBox="`0 0 ${totalChartWidth} ${chart.height}`" :style="`max-width: ${totalChartWidth}px`" aria-hidden="true">
      <title>HSQC spectrum for {{ compound.plainTextTitle }}</title>
      <!-- X axis (13C) -->
      <g class="x-axis">
        <!-- Grid -->
        <rect
          v-for="n in xRange"
          fill="#dbdbdb"
          width="1"
          :height="chart.height - chart.padding - chart.offset + 8"
          :x="xTickWidth * (n / 10) + chart.padding"
          :y="chart.padding"
          :key="`xAxisTick${n}`">
        </rect>
        <!-- Labels -->
        <text
          v-for="n in xRange"
          :x="xTickWidth * ((xRange.length - 1) - n / 10) + chart.padding"
          :y="chart.height - (chart.offset - 25)"
          :key="`xAxisLabel${n}`">
          <tspan text-anchor="middle">{{ n }}</tspan>  
        </text>
      </g>
      <!-- Y axis (1H) -->
      <g class="y-axis">
        <!-- Grid -->
        <rect
          v-for="n in yRange"
          fill="#dbdbdb"
          height="1"
          :width="chart.width + 8"
          :x="chart.padding"
          :y="yTickWidth * n + chart.padding"
          :key="`yAxisTick${n}`">
        </rect>
        <!-- Labels -->
        <text
          v-for="n in yRange"
          :x="chart.width + chart.padding + 15"
          :y="yTickWidth * n + chart.padding + 5"
          :key="`yAxisLabel${n}`">
          <tspan text-anchor="middle">{{ n }}</tspan>  
        </text>
      </g>
      <!-- Points -->
      <g class="points">
        <circle
          v-for="(dot, idx) in points"
          r="6"
          :cx="dot.xPos"
          :cy="dot.yPos"
          :fill="dot.colour"
          :key="`dot${idx}`">
          <title>{{ dot.xValue }}, {{ dot.yValue }} ({{ dot.protons }})</title>
        </circle>
      </g> -->
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TabHsqc',
  data() {
    return {
      chart: {
        width: 880,
        height: 400,
        offset: 30,
        padding: 25,
      }
    }
  },
  props: {
    compound: {
      type: Object,
      required: true
    }
  },
  methods: {
    switchTab(id) {
      this.$emit('switchTab', id);
    },
  },
  computed: {
    totalChartWidth() {
      return this.chart.width + (this.chart.padding * 2);
    },
    nmrDataWithBothValues() {
      return this.compound.nmrData.filter(c => c.carbonValue !== null && c.protons > 0);
    },
    highestCarbonValue() {
      return Math.max(...this.nmrDataWithBothValues.map(c => c.carbonValue.value));
    },
    highestProtonValue() {
      return Math.max(...this.nmrDataWithBothValues.flatMap(x => x.protonValues).map(p => p.value));
    },
    xRange() {
      // Set 140 as the minimum starting point for the x-axis, then adjust upwards
      // in multiples of 10 based on the highest carbon value in the data set.
      const start = Math.max(15, Math.ceil(this.highestCarbonValue / 10) + 1);
      // Returns, e.g. [140, 130 ... 10, 0]
      return Array.from({ length: start }, (v, i) => i * 10).reverse();
    },
    yRange() {
      // Set 7 as the minimum starting point for the y-axis, then adjust upwards
      // in multiples of 1 based on the highest proton value in the data set.
      const start = Math.max(8, Math.ceil(this.highestProtonValue + 1));
      // Returns, e.g. [0, 1, 2 ... 5, 6, 7]
      return Array.from({ length: start }, (v, i) => i);
    },
    xTickWidth() {
      return this.chart.width / (this.xRange.length - 1);
    },
    yTickWidth() {
      return (this.chart.height - this.chart.padding - this.chart.offset) / (this.yRange.length - 1);
    },
    points() {
      let points = [];
      // Only plot atoms with both 13C and 1H values
      for (const c of this.nmrDataWithBothValues) {
        for (let i = 0; i < c.protonValues.length; i++) {
          const point = {
            // Tooltips
            xValue: c.carbonValue.value.toFixed(1),
            yValue: c.protonValues[i].value.toFixed(2),
            protons: c.protons,
            // Dots
            colour: ['', 'red', 'green', 'black'][c.protons],
            // Round negative 13C and 1H shift values to 0
            xPos: (this.chart.width + this.chart.padding)
                    - (Math.max(0, c.carbonValue.value) / this.xRange[0])
                    * this.chart.width,
            yPos: (Math.max(0, c.protonValues[i].value) / this.yRange[this.yRange.length - 1])
                    * (this.chart.height - this.chart.padding - this.chart.offset)
                    + this.chart.padding,
          }
          points.push(point);
        }
      }
      return points;
    },
  },
};
</script>
