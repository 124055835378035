<template>
  <ul aria-labelledby="heading-compound-search-results">
    <li v-for="compound in compounds" :key="compound.compoundId" class="capsule mb-4">
      <article class="columns is-marginless is-gapless">
        
        <!-- IMAGE -->
        <router-link
          tabindex="-1"
          aria-hidden="true"
          :to="{ name: 'Compound', params: { id: compound.compoundId }}"
          class="column is-narrow column-border-right is-border-tablet">
          <CompoundImage :compound="compound" loading="lazy" class="is-block mx-1 my-1" />
        </router-link>

        <div class="column">
          <div class="columns is-marginless is-gapless is-multiline">

            <!-- COMPOUND -->
            <div class="column is-full-tablet is-half-desktop is-5-fullhd column-border-right is-border-desktop">
              <div class="capsule__cell v-stretch">
                <router-link
                  :to="{ name: 'Compound', params: { id: compound.compoundId }}"
                  class="is-block-link v-expand">
                  <h2 class="is-size-4 has-text-link px-3 py-3 is-block-link-underline" v-html="compound.title"></h2>
                  <div class="columns is-marginless is-multiline">
                    <div class="column">
                      <p class="data-label">Molecular formula</p>
                      <p class="data-value-bold" v-html="compound.molecularFormula"></p>
                    </div>
                    <div class="column">
                      <p class="data-label">Exact mass</p>
                      <p class="data-value-bold">{{ compound.molecularWeight }}</p>
                    </div>
                    <div class="column">
                      <p class="data-label">Status</p>
                      <p class="data-value-bold">{{ compound.status }}</p>
                    </div>
                  </div>
                </router-link>
                <div class="pin-to-bottom">
                  <CapsuleLinks :compoundId="compound.compoundId" :hasMolFile="compound.hasMolFile" />
                </div>
              </div>
            </div>

            <!-- ARTICLE -->
            <div class="column is-full-tablet is-half-desktop is-7-fullhd">
              <div v-for="article in compound.articles" :key="article.articleId" class="capsule__cell v-stretch">
                <router-link
                  :to="{ name: 'Article', params: { id: article.articleId }}"
                  class="is-block-link px-3 pt-3 v-expand">
                  <span class="is-block is-size-4 has-text-link mb-4 is-block-link-underline" v-html="article.title"></span>
                  <span
                    v-if="article.authors"
                    class="is-block is-size-6 has-text-grey-darker"
                  >{{ article.authors }}</span>
                  <ArticleCitation
                    v-if="article.journalTitle"
                    :article="article"
                    class="is-block is-size-6 mb-4 has-text-grey-darker"/>
                </router-link>
                <div class="pin-to-bottom">
                  <div class="columns is-marginless is-mobile">
                    <DoiLink
                      v-if="article.digitalObjectIdentifier"
                      :doi="article.digitalObjectIdentifier"
                      class="column is-block is-size-5 pin-to-bottom"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </article>
    </li>
  </ul>
</template>

<script>
import ArticleCitation from '@/components/shared/article-citation';
import CapsuleLinks from '@/components/shared/capsule-links';
import CompoundImage from '@/components/shared/compound-image';
import DoiLink from '@/components/shared/doi-link';
export default {
  name: 'CompoundList',
  props: {
    compounds: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ArticleCitation,
    CapsuleLinks,
    CompoundImage,
    DoiLink,
  },
};
</script>
