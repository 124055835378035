<template>
  <fieldset class="px-5 py-5">

    <!-- Required by https://www.w3.org/TR/WCAG20-TECHS/H71.html -->
    <legend class="is-sr-only">{{ legend }}</legend>
    
    <!-- EXACT MASS -->
    <div class="columns mb-0 input-text-horizontal">
      <div class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <label class="label pt-2" for="exactMass">Exact mass</label>
      </div>
      <div class="column is-9-tablet is-6-desktop field is-grouped">
        <div class="mr-4">
          <TextInput
            id="exactMass"
            rules="number|non_negative"
            suffix="Da"
            v-model.trim="exactMass"
            @input="updateSearch"
            @focus="showExactMassHelp = true"
            @blur="showExactMassHelp = false"/>
        </div>
        <div>
          <label class="is-sr-only" for="exactMassVariance">Exact mass variance</label>
          <TextInput
            id="exactMassVariance"
            rules="number|non_negative"
            prefix="±"
            placeholder="e.g. 0.1"
            v-model.trim="exactMassVariance"
            v-on="exactMass.length ? { input: updateSearch } : {}"
            @focus="showExactMassHelp = true"
            @blur="showExactMassHelp = false"/>
        </div>
      </div>
      <div class="column is-hidden-touch is-4-desktop is-relative">
        <HelpBubble
          class="is-absolute"
          style="top: 0.75rem;"
          :class="{ 'is-hidden': !showExactMassHelp }"
          helpTitle="Exact mass"
          helpText="For example: 625.40 ± 0.5 Da. Variance can be left blank."/>
      </div>
    </div>

    <!-- MOLECULAR FORMULA -->
    <TextInput
      horizontal
      id="molecularFormula"
      label="Molecular formula"
      helpTitle="Molecular formula"
      helpText="Matches both the exact formula and the formula with additional 
                elements, e.g. C5H13NO matches C5H13NOS."
      v-model.trim="molecularFormula"
      @input="updateSearch"/>

    <!-- UV MAXIMA -->
    <div class="columns mb-1 input-text-horizontal">
      <div class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <label for="uvMax0" class="label pt-2">UV maxima <span class="is-sr-only">value row 1</span></label>
      </div>
      <div class="column is-9-tablet is-6-desktop">
        <div class="columns is-mobile mb-0" v-for="(uvMax, index) in uvMaxima" :key="index">
          <div class="column field is-grouped mb-0">
            <div class="mr-4">
              <label v-if="index > 0" class="is-sr-only" :for="`uvMax${index}`">
                UV maxima value row {{ index + 1 }}
              </label>
              <TextInput
                class="control"
                rules="number|non_negative"
                suffix="nm"
                :id="`uvMax${index}`"
                v-model.trim="uvMax.value"
                @input="updateSearch"
                @focus="showUvMaximaHelp = true"
                @blur="showUvMaximaHelp = false"/>
            </div>
            <div>
              <label class="is-sr-only" :for="`uvMaxVariance${index}`">
                UV maxima variance row {{ index + 1 }}
              </label>
              <TextInput
                class="control"
                rules="number|non_negative"
                prefix="±"
                :id="`uvMaxVariance${index}`"
                v-model.trim="uvMax.variance"
                v-on="uvMax.value.length ? { input: updateSearch } : {}"
                @focus="showUvMaximaHelp = true"
                @blur="showUvMaximaHelp = false"/>
            </div>
          </div>
          <AddRemoveRowButtons
            class="column is-narrow pl-1 mr-2"
            :rowIndex="index"
            parameterId="uvMax"
            addLabel="Add more UV maxima values"
            :removeLabel="removeUvMaxLabel(uvMax.value, uvMax.variance, index)"
            @updateSearch="updateSearch"
          />
        </div>
      </div>
      <div class="column is-hidden-touch is-4-desktop is-relative">
        <HelpBubble
          class="is-absolute"
          style="top: 0.75rem;"
          :class="{ 'is-hidden': !showUvMaximaHelp }"
          helpTitle="UV maxima"
          helpText="For example: 215 ± 2 nm. To add more values, press 
                    the + button. Variance can be left blank."/>
      </div>
    </div>

    <!-- COMPOUND NAME -->
    <TextInput
      horizontal
      id="compoundName"
      label="Compound name"
      v-model="compoundName"
      @input="updateSearch"/>

  </fieldset>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabProperties',
  mixins: [SearchPanelMixin],
  data() {
    return {
      showExactMassHelp: false,
      showUvMaximaHelp: false,
    }
  },
  methods: {
    ...mapActions('search', [
      'addEmptyRowSearchParameterAction',
      'removeMultiRowSearchParameterAction',
    ]),
    removeUvMaxLabel(value, variance, index) {
      if (value) {
        return `Remove${value ? ` ${value} nm` : ''}${variance ? ` ± ${variance}` : ''}`;
      } else {
        return `Remove row ${index + 1}`;
      }
    },
  },
  computed: {
    ...mapFields('search', {
      exactMass: 'SearchParams.exactMass.value',
      exactMassVariance: 'SearchParams.exactMass.variance',
      molecularFormula: 'SearchParams.molecularFormula.value',
      compoundName: 'SearchParams.compoundName.value',
    }),
    ...mapMultiRowFields('search', {
      uvMaxima: 'SearchParams.uvMax'
    }),
  },
}
</script>
